import { Facebook, Google, Instagram, Linkedin, Youtube, TelephoneFill, EnvelopeFill, PinMapFill, GeoAltFill} from 'react-bootstrap-icons';

 
 export const bookGroups = [
    {
        id : 1,
        name :'Private Publisher',
        bookname : 'Private',
        bookname1:'Publisher',
        img : '../assets1/card1.png',
        path: '/homepvtpublisher',
        condition: true
    },
    {
        id : 2,
        name :'NCERT Books',
        bookname :'NCERT',
        bookname1:'Books',
        img : '../assets1/ncert1.png',
        path: '/homencert',
        condition: false
    },
    {
        id : 3,
        name :'Library Books',
        bookname :'Library',
        bookname1:'Books',
        img : '../assets1/library1.png',
        path: '/homelibrary'
    },
    {
        id : 4,
        name :'All CBSE Books',
        bookname :'All CBSE',
        bookname1:'Books',
        img : '../assets1/cbse1.png',
        path: '/homecbsc'
    },
    // {
    //     id : 5,
    //     name :'Other books',
    //     img : '../assets1/card5.png',
    //     path: '/'
    // },
]
export const nepGroup = [
  
    {
        id : 1,
        name :'Group 1',
        img : '../assets1/group1bgimg.png',
        path: '/group1'
    },
    {
        id : 2,
        name :'Group 2',
        img : '../assets1/group2bgimg.png',
        path: '/group2'
    },
    {
        id : 3,
        name :'Group 3',
        img : '../assets1/group3bgimg.png',
        path: '/group3'
    },
    {
        id : 4,
        name :'Group 4',
        img : '../assets1/group4bgimg.png',
        path: '/group4'
    },
  //   {
  //       id : 5,
  //       name :'Class-3 Books',
  //       img : '../assets1/class3.jpeg'
  //   },
  //   {
  //       id : 6,
  //       name :'Class-4 Books',
  //       img : '../assets1/class4.jpg'
  //   },
  //   {
  //       id : 7,
  //       name :'Class-5 Books',
  //       img : '../assets1/class5.png'
  //   },
  //   {
  //       id : 8,
  //       name :'Class-6 Books',
  //       img : '../assets1/class6.jpeg'
  //   },
  //   {
  //       id : 9,
  //       name :'Class-7 Books',
  //       img : '../assets1/class7.jpeg'
  //   },
  //   {
  //       id : 10,
  //       name :'Class-8 Books',
  //       img : '../assets1/class8.jpg'
  //   },
  //   {
  //     id : 11,
  //     name :'Class-9 Books',
  //     img : '../assets1/class7.jpeg'
  // },
  // {
  //     id : 12,
  //     name :'Class-10 Books',
  //     img : '../assets1/class8.jpg'
  // },
] 

export const group1 = [  
    {
        id : 1,
        name :'Nursey',
        img : '../assets1/nursery1.jpg',
        path: '/homenursery'
    },
    {
        id : 2,
        name :'Lkg',
        img : '../assets1/lkg.jpg',
        path: '/homelkg'
    },
    {
        id : 3,
        name :'UkG',
        img : '../assets1/ukg.jpeg',
        path: '/homeukg'
    },
    {
        id : 4,
        name :'Class 1',
        img : '../assets1/class1.jpg',
        path: '/homeclass1'
    },
    {
      id : 4,
      name :'Class 2',
      img : '../assets1/class2.jpg',
      path: '/homeclass2'
  },
] 

export const group2 = [  
  {
      id : 1,
      name :'Class 3',
      img : '../assets1/class3.jpeg',
      path: '/homeclass3'
  },
  {
      id : 2,
      name :'Class 4',
      img : '../assets1/class4.jpg',
      path: '/homeclass4'
  },
  {
      id : 3,
      name :'Class 5',
      img : '../assets1/class5.png',
      path: '/homeclass5'
  },
] 

export const group3 = [  
  {
      id : 1,
      name :'Class 6',
      img : '../assets1/class6.jpeg',
      path: '/homeclass6'
  },
  {
      id : 2,
      name :'Class 7',
      img : '../assets1/class7.jpeg',
      path: '/homeclass7'
  },
  {
      id : 3,
      name :'Class 8',
      img : '../assets1/class8.jpg',
      path: '/homeclass8'
  },
] 

export const group4 = [  
  {
      id : 1,
      name :'Class 9',
      img : '../assets1/class8.jpg',
      path: '/homeclass9'
  },
  {
      id : 2,
      name :'Class 10',
      img : '../assets1/class5.png',
      path: '/homeclass10'
  },
  {
      id : 3,
      name :'Class 11',
      img : '../assets1/class4.jpg',
      path: '/homeclass11'
  },
  {
      id : 4,
      name :'Class 12',
      img : '../assets1/class7.jpeg',
      path: '/homeclass12'
  },
] 

export const footerItem1= [
    {
      id: 1,
      header: "Quick Links",
      UnitItem: [
	{
          name: "Home",
          path:'/',
        },
        {
          name: "About Us",
          path:'/AboutUs',
        },
	{
          name: "Tutorial Video",
          path: "/blog"
        },
        {
          name: "Contact Us",
          path:'/Contactus',
        }
        
        // {
        //   name: "Terms & Condition",
        // },
        // {
        //   name: "Support",
        // },
      ],
    },
   //  {
   //    id: 2,
   //    header: "Books",
   //    UnitItem: [
   //      {
   //        name: "Private Publisher",
   //        path:'/homepvtpublisher',
   //      },
   //      {
   //        name: "NCERT Books",
   //        path:'/homencert',
   //      },
   //      {
   //        name: "Library Books",
   //        path:'/homelibrary',
   //      },
   //      {
   //        name: "All CBSC Books",
   //        path:'/homecbsc',
   //      },
   //      // {
   //      //   name: "NEP Group",
   //      //   path:'/nepgroup',
   //      // },
   //    ],
   //  },
  ];
  export const footerItem2 = [
   // {
   //   id: 1,
   //   header: "Quick Links",
   //   UnitItem: [
   //     {
   //       name: "About Us",
   //       path:'/AboutUs',
   //     },
   //     {
   //       name: "Contact Us",
   //       path:'/Contactus',
   //     },
   //     {
   //       name: "Tutorial Video",
   //       path: "/blog"
   //     },
   //     // {
   //     //   name: "Terms & Condition",
   //     // },
   //     // {
   //     //   name: "Support",
   //     // },
   //   ],
   // },
   {
     id: 2,
     header: "Books",
     UnitItem: [
       {
         name: "Private Publisher",
         path:'/homepvtpublisher',
       },
       {
         name: "NCERT Books",
         path:'/homencert',
       },
       {
         name: "Library Books",
         path:'/homelibrary',
       },
       {
         name: "All CBSE Books",
         path:'/homecbsc',
       },
       // {
       //   name: "NEP Group",
       //   path:'/nepgroup',
       // },
     ],
   },
 ];
  
  export const publishercatalog = [
    {
        id : 1,
        name :'Private Publisher',
        img : '../assets1/card1.png',
        path: '/homepvtpublisher',
        condition: true
    },
    {
        id : 2,
        name :'NCERT books',
        img : '../assets1/card2.png',
        path: '/homencert',
        condition: false
    },
    {
        id : 3,
        name :'Library books',
        img : '../assets1/card3.png',
        path: '/homelibrary'
    },
    {
        id : 4,
        name :'All CBSE books',
        img : '../assets1/card4.png',
        path: '/homecbsc'
    },
    {
        id : 5,
        name :'Other books',
        img : '../assets1/card5.png',
        path: '/'
    },
]

  export const footerContact = [
    {
      icon: <GeoAltFill size={15}/>,
      name: "494/752/1352 Near D.M Public School, Basuaghai, Bhubaneswar, Odisha PIN-752101",
    },
    
    {
      icon: <EnvelopeFill size={15}/>,
      name: "abbooksindia@gmail.com",
    },
  ];

  export const footer1Contact = [
    {
      icon: <GeoAltFill size={15}/>,
      name: "494/752/1352 Near D.M Public School, Basuaghai, Bhubaneswar, Odisha PIN-752101",
    },
    
    {
      icon: <EnvelopeFill size={15}/>,
      name: "acrbooksindiapvtltd@gmail.com",
    },
  ];


  export const socialIcons = [
    {
      icon: <Facebook size={15} />,
      Link: ""
    },
    {
      icon: <Instagram size={15}/>,
      Link: ""
    },
    {
      icon: <Linkedin size={15}/>,
      Link: ""
    },
    {
      icon: <Google size={15}/>,
      Link: ""
    },
    {
      icon: <Youtube size={15}/>,
      Link: ""
    },
  ];
  export const navList = [
    {
      id: 1,
      path: "/",
      text: "Home",
    },
    {
      id: 2,
      path: "/about",
      text: "About",
    },
    {
      id: 3,
      path: "/books",
      text: "Books",
    },
    {
      id: 4,
      path: "/dining",
      text: "Dining",
    },
    {
      id: 5,
      path: "/tutorialvideo",
      text: "TutorialVideo"
    },
    
    {
      id: 6,
      path: "/contact",
      text: "Contact",
    },
  ];
  export const state = [
    // {
    //   id : 0,
    //   name : "Select State",
    // },
    {
      id : 1,
      name : "Andhra Pradesh",
    },
    {
      id : 2,
      name : "Arunachal Pradesh",
    },
    {
      id : 3,
      name : "Assam",
    },
    {
      id : 4,
      name : "Bihar",
    },
    {
      id : 5,
      name : "Chhattisgarh",
    },
    {
      id : 6,
      name : "Goa",
    },
    {
      id : 7,
      name : "Gujarat",
    },
    {
      id : 8,
      name : "Haryana",
    },
    {
      id : 9,
      name : "Himachal Pradesh",
    },
    {
      id : 10,
      name : "Jharkhand",
    },
    {
      id : 11,
      name : "Karnataka",
    },
    {
      id : 12,
      name : "Kerala",
    },
    {
      id : 13,
      name : "Madhya Pradesh",
    },
    {
      id : 14,
      name : "Maharashtra",
    },
    {
      id : 15,
      name : "Manipur",
    },
    {
      id : 16,
      name : "Meghalaya",
    },
    {
      id : 17,
      name : "Mizoram",
    },
    {
      id : 18,
      name : "Nagaland",
    },
    {
      id : 19,
      name : "Odisha",
    },
    {
      id : 20,
      name : "Punjab",
    },
    {
      id : 21,
      name : "Rajasthan",
    },
    {
      id : 22,
      name : "Sikkim",
    },
    {
      id : 23,
      name : "Tamil Nadu",
    },
    {
      id : 24,
      name : "Telangana",
    },
    {
      id : 25,
      name : "Tripura",
    },
    {
      id : 26,
      name : "Uttar Pradesh",
    },
    {
      id : 27,
      name : "Uttarakhand",
    },
    {
      id : 28,
      name : "West Bengal",
    },
  ];

  export const statesAndDistricts = [
    // {
    //   "state": "Andhra Pradesh",
    //   "districts": [
    //     "Anantapur",
    //     "Chittoor",
    //     "East Godavari",
    //     "Guntur",
    //     "Krishna",
    //     "Kurnool",
    //     "Nellore",
    //     "Prakasam",
    //     "Srikakulam",
    //     "Visakhapatnam",
    //     "Vizianagaram",
    //     "West Godavari",
    //     "YSR Kadapa"
    //   ]
    // },
    {  
        
              "state":"Andhra Pradesh",
              "districts":[  
                 "Anantapur",
                 "Chittoor",
                 "East Godavari",
                 "Guntur",
                 "Krishna",
                 "Kurnool",
                 "Nellore",
                 "Prakasam",
                 "Srikakulam",
                 "Visakhapatnam",
                 "Vizianagaram",
                 "West Godavari",
                 "YSR Kadapa"
              ]
           },
           {  
              "state":"Arunachal Pradesh",
              "districts":[  
                 "Tawang",
                 "West Kameng",
                 "East Kameng",
                 "Papum Pare",
                 "Kurung Kumey",
                 "Kra Daadi",
                 "Lower Subansiri",
                 "Upper Subansiri",
                 "West Siang",
                 "East Siang",
                 "Siang",
                 "Upper Siang",
                 "Lower Siang",
                 "Lower Dibang Valley",
                 "Dibang Valley",
                 "Anjaw",
                 "Lohit",
                 "Namsai",
                 "Changlang",
                 "Tirap",
                 "Longding"
              ]
           },
           {  
              "state":"Assam",
              "districts":[  
                 "Baksa",
                 "Barpeta",
                 "Biswanath",
                 "Bongaigaon",
                 "Cachar",
                 "Charaideo",
                 "Chirang",
                 "Darrang",
                 "Dhemaji",
                 "Dhubri",
                 "Dibrugarh",
                 "Goalpara",
                 "Golaghat",
                 "Hailakandi",
                 "Hojai",
                 "Jorhat",
                 "Kamrup Metropolitan",
                 "Kamrup",
                 "Karbi Anglong",
                 "Karimganj",
                 "Kokrajhar",
                 "Lakhimpur",
                 "Majuli",
                 "Morigaon",
                 "Nagaon",
                 "Nalbari",
                 "Dima Hasao",
                 "Sivasagar",
                 "Sonitpur",
                 "South Salmara-Mankachar",
                 "Tinsukia",
                 "Udalguri",
                 "West Karbi Anglong"
              ]
           },
           {  
              "state":"Bihar",
              "districts":[  
                 "Araria",
                 "Arwal",
                 "Aurangabad",
                 "Banka",
                 "Begusarai",
                 "Bhagalpur",
                 "Bhojpur",
                 "Buxar",
                 "Darbhanga",
                 "East Champaran (Motihari)",
                 "Gaya",
                 "Gopalganj",
                 "Jamui",
                 "Jehanabad",
                 "Kaimur (Bhabua)",
                 "Katihar",
                 "Khagaria",
                 "Kishanganj",
                 "Lakhisarai",
                 "Madhepura",
                 "Madhubani",
                 "Munger (Monghyr)",
                 "Muzaffarpur",
                 "Nalanda",
                 "Nawada",
                 "Patna",
                 "Purnia (Purnea)",
                 "Rohtas",
                 "Saharsa",
                 "Samastipur",
                 "Saran",
                 "Sheikhpura",
                 "Sheohar",
                 "Sitamarhi",
                 "Siwan",
                 "Supaul",
                 "Vaishali",
                 "West Champaran"
              ]
           },
           {  
              "state":"Chandigarh (UT)",
              "districts":[  
                 "Chandigarh"
              ]
           },
           {  
              "state":"Chhattisgarh",
              "districts":[  
                 "Balod",
                 "Baloda Bazar",
                 "Balrampur",
                 "Bastar",
                 "Bemetara",
                 "Bijapur",
                 "Bilaspur",
                 "Dantewada (South Bastar)",
                 "Dhamtari",
                 "Durg",
                 "Gariyaband",
                 "Janjgir-Champa",
                 "Jashpur",
                 "Kabirdham (Kawardha)",
                 "Kanker (North Bastar)",
                 "Kondagaon",
                 "Korba",
                 "Korea (Koriya)",
                 "Mahasamund",
                 "Mungeli",
                 "Narayanpur",
                 "Raigarh",
                 "Raipur",
                 "Rajnandgaon",
                 "Sukma",
                 "Surajpur  ",
                 "Surguja"
              ]
           },
           {  
              "state":"Dadra and Nagar Haveli (UT)",
              "districts":[  
                 "Dadra & Nagar Haveli"
              ]
           },
           {  
              "state":"Daman and Diu (UT)",
              "districts":[  
                 "Daman",
                 "Diu"
              ]
           },
           {  
              "state":"Delhi (NCT)",
              "districts":[  
                 "Central Delhi",
                 "East Delhi",
                 "New Delhi",
                 "North Delhi",
                 "North East  Delhi",
                 "North West  Delhi",
                 "Shahdara",
                 "South Delhi",
                 "South East Delhi",
                 "South West  Delhi",
                 "West Delhi"
              ]
           },
           {  
              "state":"Goa",
              "districts":[  
                 "North Goa",
                 "South Goa"
              ]
           },
           {  
              "state":"Gujarat",
              "districts":[  
                 "Ahmedabad",
                 "Amreli",
                 "Anand",
                 "Aravalli",
                 "Banaskantha (Palanpur)",
                 "Bharuch",
                 "Bhavnagar",
                 "Botad",
                 "Chhota Udepur",
                 "Dahod",
                 "Dangs (Ahwa)",
                 "Devbhoomi Dwarka",
                 "Gandhinagar",
                 "Gir Somnath",
                 "Jamnagar",
                 "Junagadh",
                 "Kachchh",
                 "Kheda (Nadiad)",
                 "Mahisagar",
                 "Mehsana",
                 "Morbi",
                 "Narmada (Rajpipla)",
                 "Navsari",
                 "Panchmahal (Godhra)",
                 "Patan",
                 "Porbandar",
                 "Rajkot",
                 "Sabarkantha (Himmatnagar)",
                 "Surat",
                 "Surendranagar",
                 "Tapi (Vyara)",
                 "Vadodara",
                 "Valsad"
              ]
           },
           {  
              "state":"Haryana",
              "districts":[  
                 "Ambala",
                 "Bhiwani",
                 "Charkhi Dadri",
                 "Faridabad",
                 "Fatehabad",
                 "Gurgaon",
                 "Hisar",
                 "Jhajjar",
                 "Jind",
                 "Kaithal",
                 "Karnal",
                 "Kurukshetra",
                 "Mahendragarh",
                 "Mewat",
                 "Palwal",
                 "Panchkula",
                 "Panipat",
                 "Rewari",
                 "Rohtak",
                 "Sirsa",
                 "Sonipat",
                 "Yamunanagar"
              ]
           },
           {  
              "state":"Himachal Pradesh",
              "districts":[  
                 "Bilaspur",
                 "Chamba",
                 "Hamirpur",
                 "Kangra",
                 "Kinnaur",
                 "Kullu",
                 "Lahaul &amp; Spiti",
                 "Mandi",
                 "Shimla",
                 "Sirmaur (Sirmour)",
                 "Solan",
                 "Una"
              ]
           },
           {  
              "state":"Jammu and Kashmir",
              "districts":[  
                 "Anantnag",
                 "Bandipore",
                 "Baramulla",
                 "Budgam",
                 "Doda",
                 "Ganderbal",
                 "Jammu",
                 "Kargil",
                 "Kathua",
                 "Kishtwar",
                 "Kulgam",
                 "Kupwara",
                 "Leh",
                 "Poonch",
                 "Pulwama",
                 "Rajouri",
                 "Ramban",
                 "Reasi",
                 "Samba",
                 "Shopian",
                 "Srinagar",
                 "Udhampur"
              ]
           },
           {  
              "state":"Jharkhand",
              "districts":[  
                 "Bokaro",
                 "Chatra",
                 "Deoghar",
                 "Dhanbad",
                 "Dumka",
                 "East Singhbhum",
                 "Garhwa",
                 "Giridih",
                 "Godda",
                 "Gumla",
                 "Hazaribag",
                 "Jamtara",
                 "Khunti",
                 "Koderma",
                 "Latehar",
                 "Lohardaga",
                 "Pakur",
                 "Palamu",
                 "Ramgarh",
                 "Ranchi",
                 "Sahibganj",
                 "Seraikela-Kharsawan",
                 "Simdega",
                 "West Singhbhum"
              ]
           },
           {  
              "state":"Karnataka",
              "districts":[  
                 "Bagalkot",
                 "Ballari (Bellary)",
                 "Belagavi (Belgaum)",
                 "Bengaluru (Bangalore) Rural",
                 "Bengaluru (Bangalore) Urban",
                 "Bidar",
                 "Chamarajanagar",
                 "Chikballapur",
                 "Chikkamagaluru (Chikmagalur)",
                 "Chitradurga",
                 "Dakshina Kannada",
                 "Davangere",
                 "Dharwad",
                 "Gadag",
                 "Hassan",
                 "Haveri",
                 "Kalaburagi (Gulbarga)",
                 "Kodagu",
                 "Kolar",
                 "Koppal",
                 "Mandya",
                 "Mysuru (Mysore)",
                 "Raichur",
                 "Ramanagara",
                 "Shivamogga (Shimoga)",
                 "Tumakuru (Tumkur)",
                 "Udupi",
                 "Uttara Kannada (Karwar)",
                 "Vijayapura (Bijapur)",
                 "Yadgir"
              ]
           },
           {  
              "state":"Kerala",
              "districts":[  
                 "Alappuzha",
                 "Ernakulam",
                 "Idukki",
                 "Kannur",
                 "Kasaragod",
                 "Kollam",
                 "Kottayam",
                 "Kozhikode",
                 "Malappuram",
                 "Palakkad",
                 "Pathanamthitta",
                 "Thiruvananthapuram",
                 "Thrissur",
                 "Wayanad"
              ]
           },
           {  
              "state":"Lakshadweep (UT)",
              "districts":[  
                 "Agatti",
                 "Amini",
                 "Androth",
                 "Bithra",
                 "Chethlath",
                 "Kavaratti",
                 "Kadmath",
                 "Kalpeni",
                 "Kilthan",
                 "Minicoy"
              ]
           },
           {  
              "state":"Madhya Pradesh",
              "districts":[  
                 "Agar Malwa",
                 "Alirajpur",
                 "Anuppur",
                 "Ashoknagar",
                 "Balaghat",
                 "Barwani",
                 "Betul",
                 "Bhind",
                 "Bhopal",
                 "Burhanpur",
                 "Chhatarpur",
                 "Chhindwara",
                 "Damoh",
                 "Datia",
                 "Dewas",
                 "Dhar",
                 "Dindori",
                 "Guna",
                 "Gwalior",
                 "Harda",
                 "Hoshangabad",
                 "Indore",
                 "Jabalpur",
                 "Jhabua",
                 "Katni",
                 "Khandwa",
                 "Khargone",
                 "Mandla",
                 "Mandsaur",
                 "Morena",
                 "Narsinghpur",
                 "Neemuch",
                 "Panna",
                 "Raisen",
                 "Rajgarh",
                 "Ratlam",
                 "Rewa",
                 "Sagar",
                 "Satna",
                 "Sehore",
                 "Seoni",
                 "Shahdol",
                 "Shajapur",
                 "Sheopur",
                 "Shivpuri",
                 "Sidhi",
                 "Singrauli",
                 "Tikamgarh",
                 "Ujjain",
                 "Umaria",
                 "Vidisha"
              ]
           },
           {  
              "state":"Maharashtra",
              "districts":[  
                 "Ahmednagar",
                 "Akola",
                 "Amravati",
                 "Aurangabad",
                 "Beed",
                 "Bhandara",
                 "Buldhana",
                 "Chandrapur",
                 "Dhule",
                 "Gadchiroli",
                 "Gondia",
                 "Hingoli",
                 "Jalgaon",
                 "Jalna",
                 "Kolhapur",
                 "Latur",
                 "Mumbai City",
                 "Mumbai Suburban",
                 "Nagpur",
                 "Nanded",
                 "Nandurbar",
                 "Nashik",
                 "Osmanabad",
                 "Palghar",
                 "Parbhani",
                 "Pune",
                 "Raigad",
                 "Ratnagiri",
                 "Sangli",
                 "Satara",
                 "Sindhudurg",
                 "Solapur",
                 "Thane",
                 "Wardha",
                 "Washim",
                 "Yavatmal"
              ]
           },
           {  
              "state":"Manipur",
              "districts":[  
                 "Bishnupur",
                 "Chandel",
                 "Churachandpur",
                 "Imphal East",
                 "Imphal West",
                 "Jiribam",
                 "Kakching",
                 "Kamjong",
                 "Kangpokpi",
                 "Noney",
                 "Pherzawl",
                 "Senapati",
                 "Tamenglong",
                 "Tengnoupal",
                 "Thoubal",
                 "Ukhrul"
              ]
           },
           {  
              "state":"Meghalaya",
              "districts":[  
                 "East Garo Hills",
                 "East Jaintia Hills",
                 "East Khasi Hills",
                 "North Garo Hills",
                 "Ri Bhoi",
                 "South Garo Hills",
                 "South West Garo Hills ",
                 "South West Khasi Hills",
                 "West Garo Hills",
                 "West Jaintia Hills",
                 "West Khasi Hills"
              ]
           },
           {  
              "state":"Mizoram",
              "districts":[  
                 "Aizawl",
                 "Champhai",
                 "Kolasib",
                 "Lawngtlai",
                 "Lunglei",
                 "Mamit",
                 "Saiha",
                 "Serchhip"
              ]
           },
           {  
              "state":"Nagaland",
              "districts":[  
                 "Dimapur",
                 "Kiphire",
                 "Kohima",
                 "Longleng",
                 "Mokokchung",
                 "Mon",
                 "Peren",
                 "Phek",
                 "Tuensang",
                 "Wokha",
                 "Zunheboto"
              ]
           },
           {  
              "state":"Odisha",
              "districts":[  
                 "Angul",
                 "Balangir",
                 "Balasore",
                 "Bargarh",
                 "Bhadrak",
                 "Boudh",
                 "Cuttack",
                 "Deogarh",
                 "Dhenkanal",
                 "Gajapati",
                 "Ganjam",
                 "Jagatsinghapur",
                 "Jajpur",
                 "Jharsuguda",
                 "Kalahandi",
                 "Kandhamal",
                 "Kendrapara",
                 "Kendujhar (Keonjhar)",
                 "Khordha",
                 "Koraput",
                 "Malkangiri",
                 "Mayurbhanj",
                 "Nabarangpur",
                 "Nayagarh",
                 "Nuapada",
                 "Puri",
                 "Rayagada",
                 "Sambalpur",
                 "Sonepur",
                 "Sundargarh"
              ]
           },
           {  
              "state":"Puducherry (UT)",
              "districts":[  
                 "Karaikal",
                 "Mahe",
                 "Pondicherry",
                 "Yanam"
              ]
           },
           {  
              "state":"Punjab",
              "districts":[  
                 "Amritsar",
                 "Barnala",
                 "Bathinda",
                 "Faridkot",
                 "Fatehgarh Sahib",
                 "Fazilka",
                 "Ferozepur",
                 "Gurdaspur",
                 "Hoshiarpur",
                 "Jalandhar",
                 "Kapurthala",
                 "Ludhiana",
                 "Mansa",
                 "Moga",
                 "Muktsar",
                 "Nawanshahr (Shahid Bhagat Singh Nagar)",
                 "Pathankot",
                 "Patiala",
                 "Rupnagar",
                 "Sahibzada Ajit Singh Nagar (Mohali)",
                 "Sangrur",
                 "Tarn Taran"
              ]
           },
           {  
              "state":"Rajasthan",
              "districts":[  
                 "Ajmer",
                 "Alwar",
                 "Banswara",
                 "Baran",
                 "Barmer",
                 "Bharatpur",
                 "Bhilwara",
                 "Bikaner",
                 "Bundi",
                 "Chittorgarh",
                 "Churu",
                 "Dausa",
                 "Dholpur",
                 "Dungarpur",
                 "Hanumangarh",
                 "Jaipur",
                 "Jaisalmer",
                 "Jalore",
                 "Jhalawar",
                 "Jhunjhunu",
                 "Jodhpur",
                 "Karauli",
                 "Kota",
                 "Nagaur",
                 "Pali",
                 "Pratapgarh",
                 "Rajsamand",
                 "Sawai Madhopur",
                 "Sikar",
                 "Sirohi",
                 "Sri Ganganagar",
                 "Tonk",
                 "Udaipur"
              ]
           },
           {  
              "state":"Sikkim",
              "districts":[  
                 "East Sikkim",
                 "North Sikkim",
                 "South Sikkim",
                 "West Sikkim"
              ]
           },
           {  
              "state":"Tamil Nadu",
              "districts":[  
                 "Ariyalur",
                 "Chennai",
                 "Coimbatore",
                 "Cuddalore",
                 "Dharmapuri",
                 "Dindigul",
                 "Erode",
                 "Kanchipuram",
                 "Kanyakumari",
                 "Karur",
                 "Krishnagiri",
                 "Madurai",
                 "Nagapattinam",
                 "Namakkal",
                 "Nilgiris",
                 "Perambalur",
                 "Pudukkottai",
                 "Ramanathapuram",
                 "Salem",
                 "Sivaganga",
                 "Thanjavur",
                 "Theni",
                 "Thoothukudi (Tuticorin)",
                 "Tiruchirappalli",
                 "Tirunelveli",
                 "Tiruppur",
                 "Tiruvallur",
                 "Tiruvannamalai",
                 "Tiruvarur",
                 "Vellore",
                 "Viluppuram",
                 "Virudhunagar"
              ]
           },
           {  
              "state":"Telangana",
              "districts":[  
                 "Adilabad",
                 "Bhadradri Kothagudem",
                 "Hyderabad",
                 "Jagtial",
                 "Jangaon",
                 "Jayashankar Bhoopalpally",
                 "Jogulamba Gadwal",
                 "Kamareddy",
                 "Karimnagar",
                 "Khammam",
                 "Komaram Bheem Asifabad",
                 "Mahabubabad",
                 "Mahabubnagar",
                 "Mancherial",
                 "Medak",
                 "Medchal",
                 "Nagarkurnool",
                 "Nalgonda",
                 "Nirmal",
                 "Nizamabad",
                 "Peddapalli",
                 "Rajanna Sircilla",
                 "Rangareddy",
                 "Sangareddy",
                 "Siddipet",
                 "Suryapet",
                 "Vikarabad",
                 "Wanaparthy",
                 "Warangal (Rural)",
                 "Warangal (Urban)",
                 "Yadadri Bhuvanagiri"
              ]
           },
           {  
              "state":"Tripura",
              "districts":[  
                 "Dhalai",
                 "Gomati",
                 "Khowai",
                 "North Tripura",
                 "Sepahijala",
                 "South Tripura",
                 "Unakoti",
                 "West Tripura"
              ]
           },
           {  
              "state":"Uttarakhand",
              "districts":[  
                 "Almora",
                 "Bageshwar",
                 "Chamoli",
                 "Champawat",
                 "Dehradun",
                 "Haridwar",
                 "Nainital",
                 "Pauri Garhwal",
                 "Pithoragarh",
                 "Rudraprayag",
                 "Tehri Garhwal",
                 "Udham Singh Nagar",
                 "Uttarkashi"
              ]
           },
           {  
              "state":"Uttar Pradesh",
              "districts":[  
                 "Agra",
                 "Aligarh",
                 "Allahabad",
                 "Ambedkar Nagar",
                 "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                 "Amroha (J.P. Nagar)",
                 "Auraiya",
                 "Azamgarh",
                 "Baghpat",
                 "Bahraich",
                 "Ballia",
                 "Balrampur",
                 "Banda",
                 "Barabanki",
                 "Bareilly",
                 "Basti",
                 "Bhadohi",
                 "Bijnor",
                 "Budaun",
                 "Bulandshahr",
                 "Chandauli",
                 "Chitrakoot",
                 "Deoria",
                 "Etah",
                 "Etawah",
                 "Faizabad",
                 "Farrukhabad",
                 "Fatehpur",
                 "Firozabad",
                 "Gautam Buddha Nagar",
                 "Ghaziabad",
                 "Ghazipur",
                 "Gonda",
                 "Gorakhpur",
                 "Hamirpur",
                 "Hapur (Panchsheel Nagar)",
                 "Hardoi",
                 "Hathras",
                 "Jalaun",
                 "Jaunpur",
                 "Jhansi",
                 "Kannauj",
                 "Kanpur Dehat",
                 "Kanpur Nagar",
                 "Kanshiram Nagar (Kasganj)",
                 "Kaushambi",
                 "Kushinagar (Padrauna)",
                 "Lakhimpur - Kheri",
                 "Lalitpur",
                 "Lucknow",
                 "Maharajganj",
                 "Mahoba",
                 "Mainpuri",
                 "Mathura",
                 "Mau",
                 "Meerut",
                 "Mirzapur",
                 "Moradabad",
                 "Muzaffarnagar",
                 "Pilibhit",
                 "Pratapgarh",
                 "RaeBareli",
                 "Rampur",
                 "Saharanpur",
                 "Sambhal (Bhim Nagar)",
                 "Sant Kabir Nagar",
                 "Shahjahanpur",
                 "Shamali (Prabuddh Nagar)",
                 "Shravasti",
                 "Siddharth Nagar",
                 "Sitapur",
                 "Sonbhadra",
                 "Sultanpur",
                 "Unnao",
                 "Varanasi"
              ]
           },
           {  
              "state":"West Bengal",
              "districts":[  
                 "Alipurduar",
                 "Bankura",
                 "Birbhum",
                 "Burdwan (Bardhaman)",
                 "Cooch Behar",
                 "Dakshin Dinajpur (South Dinajpur)",
                 "Darjeeling",
                 "Hooghly",
                 "Howrah",
                 "Jalpaiguri",
                 "Kalimpong",
                 "Kolkata",
                 "Malda",
                 "Murshidabad",
                 "Nadia",
                 "North 24 Parganas",
                 "Paschim Medinipur (West Medinipur)",
                 "Purba Medinipur (East Medinipur)",
                 "Purulia",
                 "South 24 Parganas",
                 "Uttar Dinajpur (North Dinajpur)"
              ]
           }
        
     
    // ... Add all other states and their districts
  ];
  