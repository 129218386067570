import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import TutorialImage from "./../assets/tutorialvdo.jpg";
import TopNav from './Topnav';
import Footer1 from "./Footer1";

export default function TutorialVideo(){
    return(
        <>
         
    <Wrapper1 id="about" className="container-fluid  d-flex justify-content-center"> 
        <h1 className="text-center text-white menufont" style={{paddingTop:'10%'}}>Tutorial Video</h1>     
        </Wrapper1>
        <br />
        <Wrapper id="services" >
        <div className="lightBg" style={{ padding: "100px 0" }}>
            <div className="mx-5">
            <h1>Tutorial Video</h1>
            <h2 className="text-danger">Page under construction.</h2>
            </div>
        </div>
        </Wrapper>
        </>
    )
}

const Wrapper = styled.section`
  width: 100%;
`;
const Wrapper1 = styled.section`
  padding-top: 0px;
  width: 100%;
  min-height: 414px;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${TutorialImage}) center/cover no-repeat;
  @media (max-width: 960px) {
    flex-direction: column;
  }
  
  transition: transform 0.3s ease-in-out;
`;
