import React,{useState} from "react";
import Sidebar  from "../../Common/Sidebar";
import Header from '../../Common/Navbar';
import { Button } from "react-bootstrap";
import BookGroupModal from "./BookGroupModal"

function PrivatePublisher(){
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle)
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

    return(
        
        <div className="container-fluid m-1">
           <BookGroupModal />
        </div>
     
    )
}

export default PrivatePublisher;