import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const BASE_URL = require('../BaseURL/BaseURL');

const DisplayFilesComponent = () => {
    const [images, setImages] = useState([]);
    const {publisher_id} = useParams();

  const fetchImages = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/publisherimage/`+publisher_id);
        setImages(res.data);
    } catch (err) {
        console.error(err);
    }
};

useEffect(() => {
    fetchImages();
}, []);


  return (
    <div >
       {images.map(image => (
                    <>
                  
                    <embed src={`${BASE_URL}/${image.pdf_path}`} type="application/pdf" width="100%" height="850" />                    
                       
                     
                    
                    </>
                ))}
      
    </div>
  );
};

export default DisplayFilesComponent;
