import React, {useEffect, useState,useRef} from "react";
import axios from "axios";
import Pagination from './Pagination';
import { Table, Form,Row,Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Sidebar  from "./../Common/Sidebar";
import Header from './../Common/Navbar';

const BASE_URL = require('../BaseURL/BaseURL');

const TableWithPagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [customer,setCustomer] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  
  useEffect(() => {
    axios.get(`${BASE_URL}/customercontact`)
    .then(res => setCustomer(res.data))
    .catch(err => console.log(err));
  }, [])

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = customer.slice(indexOfFirstItem, indexOfLastItem);  
    const paginate = (pageNumber) => setCurrentPage(pageNumber); 

    const handleItemsPerPageChange = (e) => {
      setItemsPerPage(Number(e.target.value));
      setCurrentPage(1); // Reset to the first page whenever items per page change
    };

    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle)
    }

  
    return (
       
      <main className='container-fluid '><br/><br/>
        <h1>Contact Details</h1>
        <div className='container-fluid customer_table border pt-4'>
        <div className="d-flex justify-content-between mb-2">
            <Form.Group as={Row}  controlId="formPlaintextEmail">
              <Form.Label column sm="4"><h4>Shows: &nbsp;&nbsp;&nbsp;&nbsp;</h4></Form.Label>
              <Col sm="8">
              <Form.Select as="select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </Form.Select>
              </Col>
          </Form.Group>
          </div>
          <Table striped bordered hover responsive>
          <thead className='table-head'>
            <tr>
              <th style={{width:'80px'}}>Sl No.</th>
              {/* <th>Res Id</th> */}
              <th className="text-center" style={{width:'300px'}}>Name</th>
              <th className="text-center" style={{width:'400px'}}>Email</th>
              <th className="text-center" >Phone No</th>
              <th className="text-center" >Message</th>
            </tr>
          </thead>
          <tbody>
                {currentItems.map((data, index) => (
                      <tr key={index}>
                        <td>{indexOfFirstItem + index + 1}</td>
                        <td>{data.fname} {data.lname}</td>
                        <td className="text-center">{data.email}</td>
                        <td className="text-center">{data.phone}</td>
                        <td className="message-cell" >{data.message}</td>                        
                      </tr>
                    ))
                  }
          </tbody>
        </Table>
        <div className="d-flex justify-content-end ">
        <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(customer.length / itemsPerPage)}
            onPageChange={paginate}
          />
        </div>
        
      </div>
      </main>
   
    );
  };
  
  export default TableWithPagination;