import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Swal from 'sweetalert2';

const BASE_URL = require('../../BaseURL/BaseURL');


const ImageUpload = () => {
    const [bookgroup, setbookgroup] = useState('Library Books');
    const [name, setName] = useState('');
    const [subject, setsubject] = useState('');
    const [isbn, setisbn] = useState('');
    const [mrp, setmrp] = useState('');
    const [disc, setdisc] = useState('');
    const [file, setFile] = useState(null);
    const [images, setImages] = useState([]);
    const [librarycatagory, setlibrarycatagory] = useState(false);
    const [fileError, setFileError] = useState('');
    const [mrpError, setMrpError] = useState('');
    const [discError, setDiscError] = useState('');
    const [visibleCount, setVisibleCount] = useState(10);

    const handleBookGroup = (e) => {
        setbookgroup(e.target.value);
    };
    const handleName = (e) => {
        const value = e.target.value;
        setName(value);
    };
    const handleSubject= (e) => {
        const value = e.target.value;
       setsubject(value);
    };
    const handleIsbn= (e) => {
        setisbn(e.target.value);
    };
    const validateMRP = (value) => {
        if (value <= 0) {
          return 'MRP must be greater than zero.';
        }
        return '';
      };
    const handleMrp= (e) => {
        const value = e.target.value;
        setmrp(value);
        setMrpError(validateMRP(value));
    };

    const validateDiscount = (value) => {
        if (value < 0 || value > 100) {
          return 'Discount must be between 0 and 100.';
        }
        return '';
      };
    const handleDisc= (e) => {
        const { value } = e.target;
        setdisc(value);
    
        const errorMessage = validateDiscount(value);
        setDiscError(errorMessage);
    }; 

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > 50 * 1024) { // 50KB limit
        setFileError('File size exceeds 50KB');
        e.target.value = null; // Clear the file input
    } else {
        setFile(selectedFile);
        setFileError('');
    }
  };
  const [selectedlibraryOption, setSelectedlibraryOption] = useState('');
  const handlelibraryChange = (e) => {
      setSelectedlibraryOption(e.target.value);
      setlibrarycatagory(true);

  };
  const [libcatagory, setlibcatagory] = useState('');
    const handlelibrarycatagoryChange = (e) => {
        setlibcatagory(e.target.value);
    };
  

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errorMessage = validateDiscount(disc);
        const validationError = validateMRP(mrp);
         if (errorMessage) {
            setDiscError(errorMessage);
          }
         else if (validationError) {
            setMrpError(validationError);
        }
        else{
        const formData = new FormData();
        formData.append('image', file);
        formData.append('name', name);
        formData.append('subject', subject);
        formData.append('isbn', isbn);
        formData.append('mrp', mrp);
        formData.append('disc', disc);
        formData.append('bookgroup', bookgroup);
        formData.append('selectedlibraryOption', selectedlibraryOption);
        formData.append('libcatagory', libcatagory);
        try {
            await axios.post(`${BASE_URL}/upload`,formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire({
                icon: 'success',
                title: '<span style="color: green;">Submitted successfully.!</span>',
              }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                    fetchImages();
                }
              });
            
        } catch (err) {
            console.error(err);
            alert('Error uploading file.');
        }
    }
    };

    const fetchImages = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/libimages`);
            setImages(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    const showMoreCards = () => {
        setVisibleCount((prevCount) => prevCount + 10);
    };

    const handleDelete = async (id) => {
        try {
            Swal.fire({
                title: 'Are you sure you want to delete?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.delete(`${BASE_URL}/delete/${id}`);
                    Swal.fire(
                        'Deleted!',
                        'Your data has been deleted.',
                        'success'
                    ).then(() => {
                        window.location.reload(); // Refresh the page after deletion
                    });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'Cancelled',
                        'Your data is safe ',
                        'error'
                    );
                }
            });
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <div className='container-fluid'>
            <h1>          
                <input type="text" className='text-dark' onChange={handleBookGroup} value='Library Books' style={{border:'none'}} readOnly />
            </h1>
            <form  className='row' onSubmit={handleSubmit}>
            <div className='col-lg-6 mb-3'>
            <label className='labelfont mb-1'>Book Name</label>
                <input className='form-control ' style={{fontSize:'17px'}}  type='text' onChange={handleName} placeholder='Book Name' required/>
                </div>
                <div className='col-lg-6 mb-3'>
                <label className='labelfont mb-1'>Subject</label>
                <input className='form-control ' style={{fontSize:'17px'}}  type='text' onChange={handleSubject} placeholder='Subject' required/>
                </div>
                <div className='col-lg-6 mb-3'>
                <label className='labelfont mb-1'>ISBN No.</label>
                <input className='form-control' style={{fontSize:'17px'}}  type='text' onChange={handleIsbn} placeholder='ISBN No.' />
                </div>
                <div className='col-lg-6 mb-3'>
                <label className='labelfont mb-1'>MRP</label>
                <input className='form-control ' style={{fontSize:'17px'}}  type='number' onChange={handleMrp} placeholder='MRP' required/>
                {mrpError && <h3 className="error-message text-danger mt-0  ">{mrpError}*</h3>}
                </div>
                <div className='col-lg-6 mb-3'>
                <label className='labelfont mb-1'>Discount%</label>
                <input className='form-control ' style={{fontSize:'17px'}}  type='number' onChange={handleDisc} placeholder='Discount%' required/>
                {discError && <h3 className="error-message text-danger mt-0  ">{discError}*</h3>}
                </div>
                <div className='col-lg-6 mb-3'>
                <label className='labelfont mb-1'>Upload Image<span style={{fontWeight:'bold'}}>(.jpg, .PNG, .jpeg, .webp)</span></label>
                <input className='form-control ' style={{fontSize:'17px'}} accept="image/*" type="file" onChange={handleFileChange} required/>
                {fileError && <h3 className="error-message text-danger mt-0">{fileError}*</h3>}
                </div>
                <div className='col-lg-6'>
                <label className='labelfont mb-1'>Book Catagory</label>
                                <select class="form-select mb-3" style={{fontSize:'17px'}} onChange={handlelibraryChange} aria-label="Default select example" required>
                                <option value="">Select Library Book </option>
                                <option value="motivational">Motivational</option>
                                <option value="curriculum">Curriculum</option>
                                <option value="language">Language</option>
                                <option value="biopic">Biopic</option> 
                                <option value="novel">Novel</option>                       
                            </select>
                            </div>
                            { librarycatagory && <div className='col-lg-6'>
                <label className='labelfont mb-1'>Class</label>
                <select class="form-select" style={{fontSize:'17px'}} onChange={handlelibrarycatagoryChange} aria-label="Default select example" required>
                                <option value="">Select Library Book Catagory</option>
                                <option value="documentry">Documentary</option> 
                                <option value="reftype">Ref. type</option>                              
                            </select>
                </div>}
                <div className='col-lg-12'>
                <button className='btn btn-info form-control mt-2 mb-5' style={{fontSize:'18px'}}   type="submit">Submit</button>
                </div>               
            </form>
            <Row md={5} xs={2}>
                {images.slice(0, visibleCount).map(image => (
                    <>
                    <Col className='container  mb-5  flip-card'>
                    <div className='flip-card-inner'>
                        <div className='container pvtpublishercard bg-light py-3 flip-card-front' >
                            <img key={image.id} src={`${BASE_URL}/uploads/${image.image}`} alt="Uploaded" width={'100%'} height={150}/>
                            <h2 className='text-center mt-3 mb-0'>{image.name}</h2>
                            <p className='text-center my-0' style={{fontSize:'17px'}}>{image.subject}</p>
                            <p className='text-center my-0'>ISBN :&nbsp;{image.isbn}</p>
                            <p className='text-center my-0' style={{fontSize:'15px'}}>₹{image.mrp}&nbsp;&nbsp;&nbsp;<span className='textsuccess'>{image.disc}%off</span></p>
                        </div>
                        <div class="flip-card-back ">
                            <div className='d-flex flip-card-back-btn'>
                                <Link to={`/libupdate/${image.id}`} className='btn btn-success mx-1'><i class="bi bi-pencil-square" style={{fontSize:'20px'}}></i></Link>
                                <button className='btn btn-danger mx-1'onClick={e => handleDelete(image.id)}><i class="bi bi-trash" style={{fontSize:'20px'}}></i></button>
                            </div>
                        </div>
                        </div>
                     
                     
                    </Col>
                    </>
                ))}
            </Row>
            {visibleCount < images.length && (
                <div className='d-flex justify-content-center'>
                <button onClick={showMoreCards} className='btn btn-info px-5'>
                    Show More
                </button>
                </div>
            )}
        </div>
    );
};

export default ImageUpload;
