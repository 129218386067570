import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const BASE_URL = require('../BaseURL/BaseURL');

const App = () => {
    const [count, setCount] = useState(13000);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/visitor`);
            if (isMounted.current) {
                setCount(response.data.count);
            }
        } catch (error) {
            console.error('There was an error fetching the visitor count!', error);
        }
    };

    const formatNumberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <div style={{width:'max-content'}}>
            <h3 style={{fontFamily: " Courier New, monospace"}}>Visitor Count: {formatNumberWithCommas(count)}</h3>
        </div>
    );
};

export default App;
