import React, {useState, useEffect, useRef } from "react";
import styled from "styled-components";
import TutorialImage from "./../../assets/tutorialvdo.jpg";
import { group2 } from "./../../data/Data";
import TopNav from '../Topnav';
import Footer1 from "../Footer1";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import { Link } from "react-router-dom";
import Group2img from "../../../src/assets/group2bg.png"

export default function HomeGroup1(){

    return(
        <>
      
        <Wrapper1 className="header-div">
          <h2 className="text-center text-white header-text">Group 2</h2>     
        </Wrapper1>
        <Wrapper id="services" className="bg-light" >
        <div className="d-flex justify-content-end me-5 pointer">
                <Link to="/#section2"><h3 className="text-info mt-3">Back</h3></Link>
            </div> 
            <div className="card-list-container container-fluid d-flex justify-content-center">
            <div className="card-list pb-5">         
              {group2.map((book) => (
                   <Col key={book.id} className='container my-5 px-0'>
                   <Link to={book.path}><Card className='cardg1_div' style={{ borderRadius:'35px'}}>
                       <Card.Img variant="top" src={book.img} height={300} className='p-2' style={{borderRadius:'35px'}}/>
                   </Card></Link>
                   <h2 className='text-center mt-3'>{book.name}</h2>
                   </Col>           
              ))}
           </div>
           </div>
        </Wrapper>
        
        </>
    )
}

const Wrapper = styled.section`
  width: 100%;
`;
const Wrapper1 = styled.section`
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.5), 0 8px 22px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  min-height: 150px;
  background-color: darkgreen;
  
`;
