import React ,{useState} from "react";
import Sidebar  from "../Common/Sidebar";
import Header from '../Common/Navbar'

export default function Dashboard({ children }){
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle)
    }
  
    return (
      <div className='grid-container'>
        <Header OpenSidebar={OpenSidebar}/>
        <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
        <main>{children}</main>
      </div>
    )
}