import React, { useState, useEffect } from 'react';
import { Link, useParams ,useNavigate} from 'react-router-dom';
import SidebarLogo from './../assets/logo1.png';
import { Dashboarddata } from '../Components/SidebarData';
import SubMenu from './DashboardSubmenu';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Button } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';

const BASE_URL = require('../BaseURL/BaseURL');

function Sidebar({ openSidebarToggle, OpenSidebar }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newpassword, setnewPassword] = useState('');
  const [userId, setUserId] = useState(id); // Set userId to the value of id from useParams
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [shownewPassword, setnewShowPassword] = useState(false);

  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUsername(parsedUser.username || '');
      setPassword(parsedUser.password || '');
      setUserId(parsedUser.id || id); // Set userId to the parsedUser id or id from useParams
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${BASE_URL}/updateUser/${userId}`, {
        username,
        newpassword
      });
      console.log(response.data);

      // Update local storage with new user data
      const updatedUser = { id: userId, password: newpassword };
      localStorage.setItem('user', JSON.stringify(updatedUser));
      window.location.reload();

      modalClose();
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const handleLogout = () => {
    localStorage.clear(); 
    navigate('/login');
    window.location.reload();
  };

  return (
    <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
      <div className='sidebar-title mb-2'>
        <div className='sidebar-brand mt-2'>
          <img style={{ borderRadius: '50%' }} src={SidebarLogo} width={50} height={50} alt="Logo" />&nbsp;
          <h1 className='mt-2'>A.B. Books India</h1>
        </div>
        <span className='icon close_icon text-dark' onClick={OpenSidebar}>X</span>
      </div>

      <ul className='sidebar-list'>
        <h3 className='ms-3 mt-1 pointer text-white sidebarlistbg' onClick={modalShow}>
          <span style={{ fontSize: '20px' }} className='bi bi-person-circle ms-3 me-3'></span>Profile
        </h3>

        <Modal size='md' show={show} onHide={modalClose} dialogClassName="modal-dialog-start" style={{ backgroundColor: 'transparent',borderRadius:'15px' }} centered>
          <Modal.Header closeButton></Modal.Header>
            <Modal.Body >
              <Form className="pt-3 container" onSubmit={handleSubmit}>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
        <Form.Label column sm="4" style={{ fontSize: '16px' }}>
          User Name : 
        </Form.Label>
        <Col sm="8">
          <Form.Control placeholder="User Name" style={{ fontSize: '19px' }}
                      maxLength={20}
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                      readonly />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
        <Form.Label column sm="4" style={{ fontSize: '16px' }}>
         Current Password : 
        </Form.Label>
        <Col sm="8" className="position-relative">
          <Form.Control  placeholder="Current Password" style={{ fontSize: '19px' }}
          type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      readOnly />
                       <div
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: 'absolute',
                  top: '45%',
                  right: '25px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
        <Form.Label column sm="4" style={{ fontSize: '16px' }}>
          New Password : 
        </Form.Label>
        <Col sm="8" className="position-relative">
          <Form.Control  placeholder="New Password" style={{ fontSize: '19px' }}
          type="password"
                      value={newpassword}
                      onChange={e => setnewPassword(e.target.value)}
                      required />
                       {/* <div
                onClick={() => setnewShowPassword(!shownewPassword)}
                style={{
                  position: 'absolute',
                  top: '45%',
                  right: '25px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}
              >
                {shownewPassword ? <FaEyeSlash /> : <FaEye />}
              </div> */}
        </Col>
      </Form.Group>
                <Row className="my-4">
                  <Col className='d-flex justify-content-center mt-2'>
                    <Button type='submit' className="btn btn-primary text-white w-100" style={{ fontSize: '17px' }}>
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
              {error && <p className="text-danger mt-3">{error}</p>}
            </Modal.Body>
          
        </Modal>

        {Dashboarddata.map((item, index) => {
          return (
            <SubMenu
              item={item}
              key={index}
	      
            />
          );
        })}
         <h3 className='ms-3 pointer text-white sidebarlistbg' onClick={handleLogout}>
          <span style={{ fontSize: '20px', fontWeight:'bolder' }} className='bi bi-power ms-2 me-4'></span>Logout
        </h3>
      </ul>
    </aside>
  );
}

export default Sidebar;
