import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const BASE_URL = require('../../BaseURL/BaseURL');

const UpdateImageUpload = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [isbn, setIsbn] = useState('');
  const [mrp, setMrp] = useState('');
  const [disc, setDisc] = useState('');
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [mrpError, setMrpError] = useState('');
  const [discError, setDiscError] = useState('');

  useEffect(() => {
    axios.get(`${BASE_URL}/imagesreport/${id}`)
      .then(res => {
        const data = res.data[0]; // Assuming the response returns an array
        setName(data.name);
        setSubject(data.subject);
        setIsbn(data.isbn);
        setMrp(data.mrp);
        setDisc(data.disc);
      })
      .catch(err => console.log(err));
  }, [id]);

  const handleBookName = (e) => {
    setName(e.target.value);
  };
  const handleSubject = (e) => {
    setSubject(e.target.value);
  };
  const handleIsbn = (e) => {
    setIsbn(e.target.value);
  };
  const validateMRP = (value) => {
    if (value <= 0) {
      return 'MRP must be greater than zero.';
    }
    return '';
  };
  const handleMrp= (e) => {
    const value = e.target.value;
    setMrp(value);
    setMrpError(validateMRP(value));
};

const validateDiscount = (value) => {
    if (value < 0 || value > 100) {
      return 'Discount must be between 0 and 100.';
    }
    return '';
  };
const handleDisc= (e) => {
    const { value } = e.target;
    setDisc(value);

    const errorMessage = validateDiscount(value);
    setDiscError(errorMessage);
}; 


  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > 50 * 1024) { // 50KB limit
        setFileError('File size exceeds 50KB');
        e.target.value = null; // Clear the file input
    } else {
        setFile(selectedFile);
        setFileError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorMessage = validateDiscount(disc);
    const validationError = validateMRP(mrp);
     if (errorMessage) {
        setDiscError(errorMessage);
      }
     else if (validationError) {
        setMrpError(validationError);
    }
    else{
    const formData = new FormData();
    formData.append('image', file);
    formData.append('name', name);
    formData.append('subject', subject);
    formData.append('isbn', isbn);
    formData.append('mrp', mrp);
    formData.append('disc', disc);
    try {
      await axios.put(`${BASE_URL}/update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      Swal.fire({
        icon: 'success',
        title: 'Updated successfully.!',
      }).then((result) => {
        if (result.isConfirmed) {
            navigate('/library')
        }
      });
    } catch (err) {
      console.error(err);
      alert('Error uploading file.');
    }
  }
  };

  return (
    <div className='container'>
      <div className="modal-overlay">
        <div className="modal-content p-4 bg-light w-50 mx-3 my-2" style={{borderRadius:'15px'}}>
          <div className='d-flex justify-content-end'>
            <Link to="/library" className='btn btn-close mt-2 mb-4'></Link>
          </div>
          <form className='row' onSubmit={handleSubmit}>
            <div className='col-lg-6'>
              <input className='form-control mb-3' style={{ fontSize: '17px' }} type='text' value={name} onChange={handleBookName} placeholder='Book Name' required/>
            </div>
            <div className='col-lg-6'>
              <input className='form-control mb-3' style={{ fontSize: '17px' }} type='text' value={subject} onChange={handleSubject} placeholder='Subject' required/>
            </div>
            <div className='col-lg-6'>
              <input className='form-control mb-3' style={{ fontSize: '17px' }} type='text' value={isbn} onChange={handleIsbn} placeholder='ISBN No.' />
            </div>
            <div className='col-lg-6'>
              <input className='form-control mb-3' style={{ fontSize: '17px' }} type='number' value={mrp} onChange={handleMrp} placeholder='MRP' required/>
              {mrpError && <h3 className="error-message text-danger mt-0  ">{mrpError}*</h3>}
            </div>
            <div className='col-lg-6'>
              <input className='form-control mb-3' style={{ fontSize: '17px' }} type='number' value={disc} onChange={handleDisc} placeholder='Discount%' required/>
              {discError && <h3 className="error-message text-danger mt-0  ">{discError}*</h3>}
            </div>
            <div className='col-lg-6'>
              <input className='form-control mb-3' style={{ fontSize: '17px' }} type="file" onChange={handleFileChange} />
              {fileError && <h3 className="error-message text-danger mt-0">{fileError}*</h3>}
            </div>
            <div className='col-lg-12'>
              <button className='btn btn-info form-control mb-3 mt-3' style={{ fontSize: '18px' }} type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateImageUpload;
