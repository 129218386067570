import 'semantic-ui-css/semantic.min.css'
import React from 'react'
import './../App.css';
import {
    Container,
    Divider,
    Grid,
    Button,
    Image,
    List,
    Segment    
    
  } from 'semantic-ui-react'
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer(){
    return(
        <>
          <div>
            <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em 2em 0em' }}>
              <div className='container footer_heading'>
                <Grid inverted stackable textAlign='center'>
                  <Grid.Column width={4}>
                  <h4> Our Contacts</h4>
                    <List link inverted className='footerlink'>
                      <h5 className='mb-3 mt-4'><FaIcons.FaHome size={25}/>&nbsp;&nbsp;Address:</h5>
                      <p style={{marginLeft:'40px'}} className='mb-4'>352, S-2, Metro Classic Building,<br/>
                      Saheed Nagar, Bhubaneswar.</p>                      
                      <h5 className='mb-3'><FaIcons.FaPhone size={20}/>&nbsp;&nbsp;Phone:</h5>
                      <a href="#" > Call-Us 0674-3125757</a>
                      <br /><br />                      
                      <h5 className='mb-3'><FaIcons.FaEnvelope size={20}/>&nbsp;&nbsp;Email:</h5>
                      <a href="#" > info@scrumlin..infra.com</a>
                    </List>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <h4 className='mb-4'>Business Services</h4>
                    <List link inverted>
                      <List.Item as='a'>Link One</List.Item>
                      <List.Item as='a'>Link Two</List.Item>
                      <List.Item as='a'>Link Three</List.Item>
                      <List.Item as='a'>Link Four</List.Item>
                    </List>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <h4 className='mb-4'>Quick Links</h4>                    
                    <List link inverted >
                      <List.Item as={Link} to="/">Home</List.Item>
                      <List.Item as={Link} to="/AboutUs">About Us</List.Item>
                      <List.Item as={Link} to="/gallery">Media Gallary</List.Item>
                      <List.Item as={Link} to="/Blog">Blog</List.Item>
                      <List.Item as={Link} to="/Contactus">Contact Us</List.Item>
                    </List>
                    
                  </Grid.Column>
                  <Grid.Column width={6}>
                  <div >                
                    <h4>
                      <img style={{width: '25px', marginTop:'-8px'}} src='/logo1.png' />
                      &nbsp;RealEstate
                    </h4>
                  </div><br />
                    <p style={{marginLeft:'30px',textAlign:'justify'}}>
                      We help businesses solve their challenges and stay ahead of the competition by leveraging the latest technologies. We deliver next-generation software solutions from small to big level companies and growing startups, boosting their value and building their powerful digital future.
                    </p><br />
                    <div className='mx-4'>
                    <a href="https://www.facebook.com/">
                    <Button circular color='facebook' icon='facebook' />
                    </a>
                    <a href='#'>
                        <Button circular color='twitter' icon='twitter' />
                    </a>
                    <a href='#'>
                        <Button circular color='pink' icon='instagram' />
                    </a>
                    <a href="https://in.linkedin.com/company/scrumlin">
                        <Button circular color='linkedin' icon='linkedin' />
                    </a>
                    <a href="https://scrumlin.com/">
                        <Button circular color='google plus' icon='google' />
                    </a>
                </div>
                  </Grid.Column>
                </Grid>
                <br />
                <Image centered size='mini' src='/logo.png' />
                <Container fluid textAlign='center'>
                <List horizontal inverted divided link size='small'>
                  <List.Item as='a' href='#'>
                    Site Map
                  </List.Item>
                  <List.Item as='a' href='#'>
                    Contact Us
                  </List.Item>
                  <List.Item as='a' href='#'>
                    Terms and Conditions
                  </List.Item>
                  <List.Item as='a' href='#'>
                    Privacy Policy
                  </List.Item>
                </List>
                </Container>
                <Divider inverted section />
                <Container fluid >
                  <Grid>
                    <Grid.Column width={8}>
                        <h5 style={{marginTop:'10px'}}>Copyright © 2024 Scrumlin Technology. All Rights Reserved.</h5>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <h5 style={{float:'right'}}>Powered By<span><img src='./../scrumlin_infra.png' width={30} /></span> SCRUMLIN.INFRA</h5>
                      </Grid.Column>
                  </Grid>
                </Container>
              </div>
            </Segment>
          </div>
        </>
    );
}
export default Footer; 