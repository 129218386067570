import React, {useState, useEffect, useRef } from "react";
import styled from "styled-components";
import TopNav from './Topnav';
import Footer1 from "./Footer1";
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import { NEPdata } from "./NEPSidebarData";

const BASE_URL = require('../BaseURL/BaseURL');

export default function HomePrivatePublisher(){
    const [images, setImages] = useState([]);

    const fetchImages = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/images`);
            setImages(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    return(
        <>
        <header className='megamenu_header'>
            <TopNav />
        </header>
        <Wrapper1 className="header-div">
          <h2 className="text-center text-white header-text">NEP Group</h2>     
        </Wrapper1>
        <Wrapper id="services" className="bg-light" >
        <Row>
            <Col md={2} className="p-5 nepgroupcol">
                <Row md={1} className="container p-5 nepcontent">
                    {NEPdata.map((item) => (
                        <>
                            <Col><Link to={item.path} className="neplink"><h3 className="mb-2">{item.title}</h3></Link></Col>
                        </>
                    ))}
                </Row>
            </Col>
            <Col md={10} className="nepdivrel">
                <Row xs={1} md={5} className="px-5 py-5 g-5 mb-5">            
                    {/* {images.map((image) => (
                        <Col key={image.id}>
                        <Card className='card2_div' style={{ borderRadius:'35px'}}>
                            <Card.Img variant="top" src={`http://localhost:5000/uploads/${image.image}`} height={300} className='p-2' style={{borderRadius:'35px'}}/>
                        </Card>
                        <h2 className='text-center mt-3 mb-0'>{image.name}</h2>
                                    <h3 className='text-center my-0' >{image.subject}</h3>
                                    <h3 className='text-center my-0'>{image.isbn}</h3>
                                    <h3 className='text-center my-0'>{image.mrp}&nbsp;&nbsp;&nbsp;<span className='text-danger'>{image.disc}%off</span></h3>
                        </Col>        
                    ))} */}
                    <div className="nepdivabs">
                        <h1>NEP GROUP</h1>
                    </div>
                </Row>
            </Col>
        </Row>
        
        </Wrapper><br/><br/>
        <footer style={{zIndex:'1000'}}>
            <Footer1 />
        </footer>
        </>
    )
}

const Wrapper = styled.section`
  width: 100%;
`;
const Wrapper1 = styled.section`
  padding-top: 0px;
  width: 100%;
  min-height: 150px;
  background-color: #b2d47c;
  
`;
