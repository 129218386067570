// Filename - components/SidebarData.js

import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
    {
		title: "Home",
		path: "/",
		icon: <FaIcons.FaHome />,
	},
	{
		title: "About Us",
		path: "/Aboutus",
		icon: <AiIcons.AiFillHome />,
	},
    {
		title: "Books",
		icon: <FaIcons.FaBookOpen />,

		iconClosed: <RiIcons.RiArrowDownSFill />,
		iconOpened: <RiIcons.RiArrowUpSFill />,

		subNav: [
			{
				title: "Private publisher",
				path: "/homepvtpublisher",
				icon: <IoIcons.IoMdBook />,
			},
			{
				title: "NCERT Books",
				path: "/homencert",
				icon: <IoIcons.IoMdBook />,
			},
			{
				title: "Library Books",
				path: "/homelibrary",
				icon: <IoIcons.IoMdBook />,
			},
			{
				title: "All CBSC Books",
				path: "/homecbsc",
				icon: <IoIcons.IoMdBook />,
			},
		],
	},
    {
		title: "Tutorial Video",
		path: "/Blog",
		icon: <FaIcons.FaVideo />,
	},
	{
		title: "Contact Us",
		path: "/ContactUs",
		icon: <FaIcons.FaPhoneAlt />,
	},
    {
		title: "Login",
		path: "/Login",
		icon: <IoIcons.IoMdLogIn />,
	}
];
export const Dashboarddata = [
    {
		title: "Dashboard",
		path: "/dashboard",
		icon: <i className='bi bi-speedometer2 ' ></i>,
	},
    {
		title: "Book Group",
		icon: <FaIcons.FaBookOpen />,

		iconClosed: <RiIcons.RiArrowDownSFill />,
		iconOpened: <RiIcons.RiArrowUpSFill />,

		subNav: [
			{
				title: "Private publisher",
				path: "/pvtpublisher",
				icon: <IoIcons.IoMdBook />,
			},
			{
				title: "NCERT Books",
				path: "/ncert",
				icon: <IoIcons.IoMdBook />,
			},
			{
				title: "Library Books",
				path: "/library",
				icon: <IoIcons.IoMdBook />,
			},
			{
				title: "All CBSC Books",
				path: "/cbsc",
				icon: <IoIcons.IoMdBook />,
			},
		],
	},
	// {
	// 	title: "NEP Group",
	// 	icon: <FaIcons.FaBookOpen />,

	// 	iconClosed: <RiIcons.RiArrowDownSFill />,
	// 	iconOpened: <RiIcons.RiArrowUpSFill />,

	// 	subNav: [
	// 		{
	// 			title: "Nursery",
	// 			path: "/nursery",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "LKG",
	// 			path: "/lkg",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "UKG",
	// 			path: "/ukg",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "Class 1",
	// 			path: "/class1",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "Class 2",
	// 			path: "/class2",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "Class 3",
	// 			path: "/class3",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "Class 4",
	// 			path: "/class4",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "Class 5",
	// 			path: "/class5",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "Class 6",
	// 			path: "/class6",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "Class 7",
	// 			path: "/class7",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "Class 8",
	// 			path: "/class8",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "Class 9",
	// 			path: "/class9",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 		{
	// 			title: "Class 10",
	// 			path: "/class10",
	// 			icon: <IoIcons.IoMdBook />,
	// 		},
	// 	],
	// },
    {
		title: "Publisher Catalog",
		path: "/publisher",
		icon: <FaIcons.FaNewspaper/>,
	},
	{
		title: "Viewer Details",
		path: "/user",
		icon: <FaIcons.FaUser/>,
	},
	{
		title: "Contact Info",
		path: "/contactinfo",
		icon: <FaIcons.FaUser/>,
	},
    // {
	// 	title: "Logout",
	// 	path: "/Login",
	// 	icon: <IoIcons.IoMdPower />,
	// }
];