import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Sidebar  from "./../Common/Sidebar";
import Header from './../Common/Navbar';
import { Button } from "react-bootstrap";
import Swal from 'sweetalert2';

const BASE_URL = require('../BaseURL/BaseURL');

function PrivatePublisher(){
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle)
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [publisherbookname, setpublisherBookName] = useState('');
  const [file, setFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [images, setImages] = useState([]);
  const [fileError, setFileError] = useState('');

  const handleBookName = (e) => {
    setpublisherBookName(e.target.value);
  };

const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.size > 50 * 1024) { // 50KB limit
            setFileError('File size exceeds 50KB');
            e.target.value = null; // Clear the file input
        } else {
            setFile(selectedFile);
            setFileError('');
        }
};
const handlePdfChange = (e) => {
    setPdfFile(e.target.files[0]);
  };


  const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('image', file);
      formData.append('pdf', pdfFile);
      formData.append('publisherbookname', publisherbookname);
      
      try {
          await axios.post(`${BASE_URL}/publisherupload`,formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });
          Swal.fire({
            icon: 'success',
            title: 'Submitted successfully.!',
          }).then((result) => {
            if (result.isConfirmed) {
                window.location.reload();
                fetchImages();
            }
          });
        
      } catch (err) {
          console.error(err);
          alert('Error uploading file.');
      }
  };

  const fetchImages = async () => {
      try {
          const res = await axios.get(`${BASE_URL}/publisherimages`);
          setImages(res.data);
      } catch (err) {
          console.error(err);
      }
  };

  useEffect(() => {
      fetchImages();
  }, []);

    const handleDelete = async (publisher_id) => {
        try {
            Swal.fire({
                title: 'Are you sure you want to delete?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.delete(`${BASE_URL}/publisherdelete/` + publisher_id);
                    Swal.fire(
                        'Deleted!',
                        'Your data has been deleted.',
                        'success'
                    ).then(() => {
                        window.location.reload(); // Refresh the page after deletion
                    });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'Cancelled',
                        'Your data is safe ',
                        'error'
                    );
                }
            });
        } catch (err) {
            console.log(err);
        }
    };

    return(

        
        <div className="container-fluid m-1">
        <div className='container-fluid'>
            <h1 className='mb-3'>Publisher Books</h1>
            <form  className='row' onSubmit={handleSubmit}>
                <div className='col-lg-4 mb-1'>
                <label className='labelfont mb-2'>Publisher Name</label>
                <input className='form-control mb-2' style={{fontSize:'17px'}}  type='text' onChange={handleBookName} placeholder='Publisher Name' required/>
                </div>
                <div className='col-lg-4 mb-1'>
                <label className='labelfont mb-2' >Upload Image<span style={{fontWeight:'bold'}}>(.jpg, .PNG, .jpeg, .webp)</span></label>
                <input className='form-control mb-2' style={{fontSize:'17px'}} accept="image/*"  type="file" onChange={handleFileChange} required/>
                {fileError && <h3 className="error-message text-danger mt-0">{fileError}*</h3>}
                </div>

                <div className='col-lg-4 mb-1' >
                <label className='labelfont mb-2' >Upload PDF<span style={{fontWeight:'bold'}}>(.pdf)</span></label>
                <input className='form-control mb-2' style={{fontSize:'17px'}} type="file" id="pdf" accept=".pdf" onChange={handlePdfChange} required/>
                </div>
                <div className='col-lg-12'>
                <button className='btn btn-info form-control mb-5' style={{fontSize:'18px'}}   type="submit">Submit</button>
                </div>               
            </form>
            <div className='row d-flex '>
                {images.map(image => (
                    <>
                   <div className='container col-sm-3 mb-5  '>
                    <div className=''>
                        <div className='container pvtpublishercard bg-light py-3 '>
                            <img key={image.id} src={`${BASE_URL}/${image.image}`} alt="Uploaded" style={{borderRadius:'50%'}} width={100} height={100}/>
                            <h2 className='text-center my-1 mb-0'>{image.publisherbookname}</h2><br/>
                            <Link to={`/viewpdf/${image.publisher_id}`} className='py-5' width="600" height="400" style={{fontSize:'15px'}} target="_blank">View PDF</Link><br/> <br/>  
                            <Link to={`/publisherupdate/${image.publisher_id}`} className='btn btn-success mx-1 px-4'>Edit</Link>
                            <button className='btn btn-danger mx-1' onClick={e => handleDelete(image.publisher_id)}>Delete</button>   
                        </div>
                        {/* <div class="flip-card-back ">
                            <div className='d-flex flip-card-back-btn-publisher'>
                                <Link to={`/publisherupdate/${image.publisher_id}`} className='btn btn-success mx-1'><i class="bi bi-pencil-square" style={{fontSize:'15px'}}></i></Link>
                                <button className='btn btn-danger mx-1' onClick={e => handleDelete(image.publisher_id)}><i class="bi bi-trash" style={{fontSize:'15px'}}></i></button>
                            </div>
                        </div> */}
                        </div>
                     
                     
                    </div>
                    </>
                ))}
            </div>
        </div>
   
      </div>
    )
}

export default PrivatePublisher;