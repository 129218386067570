import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const BASE_URL = require('../BaseURL/BaseURL');

export default function ClientSlider() {
    const [images, setImages] = useState([]);
    
    const fetchImages = async () => {
      try {
          const res = await axios.get(`${BASE_URL}/publisherimages`);
          setImages(res.data);
      } catch (err) {
          console.error(err);
      }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const settings = {  
      arrows: false,    
      infinite: true,
      slidesToShow: Math.min(5, images.length), // Adjust slidesToShow based on the number of images
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: Math.min(4, images.length),
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: Math.min(3, images.length),
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: Math.min(2, images.length),
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="mainContainer container pb-4">
        <Slider {...settings}>
          {images.map((image) => (
            <div key={image.publisher_id} className="container d-flex justify-content-center">
              <Link className='text-dark' to={`/viewpdf/${image.publisher_id}`} target="_blank">
                <div>
                  <img className='text-center' src={`${BASE_URL}/${image.image}`} width={'100%'} height={50} alt={image.publisherbookname}/>
                  <h3 className='text-center'>{image.publisherbookname}</h3>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    );
}