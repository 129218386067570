import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'semantic-ui-css/semantic.min.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from './Components/Home';
import ContactUs from "./Components/ContactUs"
import Gallery from "./Components/gallery";
import Blog from './Components/Blog';
import AboutUs from './Components/AboutUs';
import Login from './Components/Login';
import Dashboard from './Components/Dashoard';
import PublisherCatalog from './Components/PublisherCatalog';
import PrivatePublisher from './Components/BookGroupDashboard/PrivatePublisher';
import UpdatePublisher from './Components/UpdatePublisher';
import HomePrivatePublisher from './Components/BookGroupHome/HomePrivatePublisher';
import UpdatePvtPublisher from './Components/BookGroupDashboard/UpdatePvtPublisher';
import NCERT from './Components/BookGroupDashboard/ncert';
import HomeNcertBook from './Components/BookGroupHome/HomeNcertBook';
import UpdateNcertBook from './Components/BookGroupDashboard/UpdateNcertBook';
import Library from './Components/BookGroupDashboard/Library';
import HomeLibraryBook from './Components/BookGroupHome/HomeLibraryBook';
import UpdateLibraryBook from './Components/BookGroupDashboard/UpdateLibraryBook';
import CBSC from './Components/BookGroupDashboard/cbsc';
import HomeCbscBook from './Components/BookGroupHome/HomeCbscBook';
import UpdateCbscBook from './Components/BookGroupDashboard/UpdateCbscBook';
import NEPGroup from './Components/NEPGroup';
import Group1 from './Components/NEPGroupHome/Group1';
import Group2 from './Components/NEPGroupHome/Group2';
import Group3 from './Components/NEPGroupHome/Group3';
import Group4 from './Components/NEPGroupHome/Group4';
import Nursery from './Components/NEPGroupHome/Nursery';
// import NurseryDash from './Components/NEPGroupDashboard/NurseryDash';
// import UpdateNurseryBook from './Components/NEPGroupDashboard/UpdateNurseryBook';
import LKG from './Components/NEPGroupHome/LKG';
// import LKGDash from './Components/NEPGroupDashboard/LKGDash';
// import UpdateLkgBook from './Components/NEPGroupDashboard/UpdateLkgBook';
import UKG from './Components/NEPGroupHome/UKG';
// import UKGDash from './Components/NEPGroupDashboard/UKGDash';
// import UpdateUkgBook from './Components/NEPGroupDashboard/UpdateUkgBook';
import Class1 from './Components/NEPGroupHome/Class1';
// import Class1Dash from './Components/NEPGroupDashboard/Class1Dash';
// import UpdateClass1Book from './Components/NEPGroupDashboard/UpdateClass1Book';
import Class2 from './Components/NEPGroupHome/Class2';
// import Class2Dash from './Components/NEPGroupDashboard/Class2Dash';
// import UpdateClass2Book from './Components/NEPGroupDashboard/UpdateClass2Book';
import Class3 from './Components/NEPGroupHome/Class3';
// import Class3Dash from './Components/NEPGroupDashboard/Class3Dash';
// import UpdateClass3Book from './Components/NEPGroupDashboard/UpdateClass3Book';
import Class4 from './Components/NEPGroupHome/Class4';
// import Class4Dash from './Components/NEPGroupDashboard/Class4Dash';
// import UpdateClass4Book from './Components/NEPGroupDashboard/UpdateClass4Book';
import Class5 from './Components/NEPGroupHome/Class5';
// import Class5Dash from './Components/NEPGroupDashboard/Class5Dash';
// import UpdateClass5Book from './Components/NEPGroupDashboard/UpdateClass5Book';
import Class6 from './Components/NEPGroupHome/Class6';
// import Class6Dash from './Components/NEPGroupDashboard/Class6Dash';
// import UpdateClass6Book from './Components/NEPGroupDashboard/UpdateClass6Book';
import Class7 from './Components/NEPGroupHome/Class7';
// import Class7Dash from './Components/NEPGroupDashboard/Class7Dash';
// import UpdateClass7Book from './Components/NEPGroupDashboard/UpdateClass7Book';
import Class8 from './Components/NEPGroupHome/Class8';
// import Class8Dash from './Components/NEPGroupDashboard/Class8Dash';
// import UpdateClass8Book from './Components/NEPGroupDashboard/UpdateClass8Book';
import Class9 from './Components/NEPGroupHome/Class9';
// import Class9Dash from './Components/NEPGroupDashboard/Class9Dash';
// import UpdateClass9Book from './Components/NEPGroupDashboard/UpdateClass9Book';
import Class10 from './Components/NEPGroupHome/Class10';
// import Class10Dash from './Components/NEPGroupDashboard/Class10Dash';
// import UpdateClass10Book from './Components/NEPGroupDashboard/UpdateClass10Book';
import Class11 from './Components/NEPGroupHome/Class11';
import Class12 from './Components/NEPGroupHome/Class12';
import ProductModal from './Components/Modal';
import ViewPdf from './Components/Viewpdf';
import ProtectedRoute from './Components/ProtectedRoute';
import HomeProtectedRoute from './Components/HomeProtectedRoute';
import User from './Components/User';
import ContactInfo from './Components/ContactUsDatabase';
import Layout from './Components/LayoutHome';
import LayoutDashboard from './Components/LayoutDashboard';

function App() {
    const [user, setUser] = useState(null);
    const [selectedBookPath, setSelectedBookPath] = useState('');
  return (
      <>
      <Router>
        <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home user={user} />
            </Layout>
          }
        />
        <Route
          path="/AboutUs"
          element={
            <Layout>
              <AboutUs />
            </Layout>
          }
        />
        <Route
          path="/gallery"
          element={
            <Layout>
              <Gallery />
            </Layout>
          }
        />
        <Route
          path="/Blog"
          element={
            <Layout>
              <Blog />
            </Layout>
          }
        />
        <Route
          path="/Contactus"
          element={
            <Layout>
              <ContactUs />
            </Layout>
          }
        />
           <Route
              path="/login"
              element={<Login setUser={setUser} />}
          />
          <Route
              path="/dashboard"
              element={
              <LayoutDashboard><ProtectedRoute user={user}>
              <Dashboard />
            </ProtectedRoute></LayoutDashboard>}
          />
          <Route
              path="/publisher"
              element={<LayoutDashboard><ProtectedRoute user={user}><PublisherCatalog /></ProtectedRoute></LayoutDashboard>}
          />
          <Route
              path="/publisherupdate/:publisher_id"
              element={<ProtectedRoute user={user}><UpdatePublisher /></ProtectedRoute>}
          />
          <Route
              path="/pvtpublisher"
              element={<LayoutDashboard><ProtectedRoute user={user}><PrivatePublisher /></ProtectedRoute></LayoutDashboard>}
          />
           <Route
              path="/update/:id"
              element={<ProtectedRoute user={user}><UpdatePvtPublisher /></ProtectedRoute>}
          />
          <Route
              path="/homepvtpublisher"
              element={<Layout><HomeProtectedRoute user={user}><HomePrivatePublisher /></HomeProtectedRoute></Layout>}
          />
          <Route
              path="/ncert"
              element={<LayoutDashboard><ProtectedRoute user={user}><NCERT /></ProtectedRoute></LayoutDashboard>}
          />
           <Route
              path="/ncertupdate/:id"
              element={<ProtectedRoute user={user}><UpdateNcertBook /></ProtectedRoute>}
          />
          <Route
              path="/homencert"
              element={<Layout><HomeProtectedRoute user={user}><HomeNcertBook /></HomeProtectedRoute></Layout>}
          />
          <Route
              path="/library"
              element={<LayoutDashboard><ProtectedRoute user={user}><Library /></ProtectedRoute></LayoutDashboard>}
          />
          <Route
              path="/libupdate/:id"
              element={<ProtectedRoute user={user}><UpdateLibraryBook /></ProtectedRoute>}
          />
          <Route
              path="/homelibrary"
              element={<Layout><HomeProtectedRoute user={user}><HomeLibraryBook /></HomeProtectedRoute></Layout>}
          />
          <Route
              path="/cbsc"
              element={<LayoutDashboard><ProtectedRoute user={user}><CBSC /></ProtectedRoute></LayoutDashboard>}
          />
           <Route
              path="/cbscupdate/:id"
              element={<ProtectedRoute user={user}><UpdateCbscBook /></ProtectedRoute>}
          />
          <Route
              path="/homecbsc"
              element={<Layout><HomeProtectedRoute user={user}><HomeCbscBook /></HomeProtectedRoute></Layout>}
          />
           <Route
              path="/nepgroup"
              element={<NEPGroup />}
          />
          <Route
              path="/group1"
              element={<Layout><HomeProtectedRoute user={user}><Group1 /></HomeProtectedRoute></Layout>}
          />
          <Route
              path="/group2"
              element={<Layout><HomeProtectedRoute user={user}><Group2 /></HomeProtectedRoute></Layout>}
          />
          <Route
              path="/group3"
              element={<Layout><HomeProtectedRoute user={user}><Group3 /></HomeProtectedRoute></Layout>}
          />
          <Route
              path="/group4"
              element={<Layout><HomeProtectedRoute user={user}><Group4 /></HomeProtectedRoute></Layout>}
          />
           <Route
              path="/homenursery"
              element={<Layout><HomeProtectedRoute user={user}><Nursery /></HomeProtectedRoute></Layout>}
          />
          {/* <Route
              path="/nursery"
              element={<NurseryDash />}
          />
          <Route
              path="/nsryupdate/:nsry_id"
              element={<UpdateNurseryBook />}
          /> */}
           <Route
              path="/homelkg"
              element={<Layout><HomeProtectedRoute user={user}><LKG /></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/lkg"
              element={<LKGDash />}
          />
          <Route
              path="/lkgupdate/:lkg_id"
              element={<UpdateLkgBook />}
          /> */}
          <Route
              path="/homeukg"
              element={<Layout><HomeProtectedRoute user={user}><UKG /></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/ukg"
              element={<UKGDash />}
          />
          <Route
              path="/ukgupdate/:ukg_id"
              element={<UpdateUkgBook />}
          /> */}
           <Route
              path="/homeclass1"
              element={<Layout><HomeProtectedRoute user={user}><Class1/></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/class1"
              element={<Class1Dash />}
          />
          <Route
              path="/class1update/:class1_id"
              element={<UpdateClass1Book />}
          /> */}
          <Route
              path="/homeclass2"
              element={<Layout><HomeProtectedRoute user={user}><Class2/></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/class2"
              element={<Class2Dash />}
          />
          <Route
              path="/class2update/:class2_id"
              element={<UpdateClass2Book />}
          /> */}
          <Route
              path="/homeclass3"
              element={<Layout><HomeProtectedRoute user={user}><Class3/></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/class3"
              element={<Class3Dash />}
          />
          <Route
              path="/class3update/:class3_id"
              element={<UpdateClass3Book />}
          /> */}
          <Route
              path="/homeclass4"
              element={<Layout><HomeProtectedRoute user={user}><Class4/></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/class4"
              element={<Class4Dash />}
          />
          <Route
              path="/class4update/:class4_id"
              element={<UpdateClass4Book />}
          /> */}
          <Route
              path="/homeclass5"
              element={<Layout><HomeProtectedRoute user={user}><Class5/></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/class5"
              element={<Class5Dash />}
          />
          <Route
              path="/class5update/:class5_id"
              element={<UpdateClass5Book />}
          /> */}
            <Route
              path="/homeclass6"
              element={<Layout><HomeProtectedRoute user={user}><Class6/></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/class6"
              element={<Class6Dash />}
          />
          <Route
              path="/class6update/:class6_id"
              element={<UpdateClass6Book />}
          /> */}
          <Route
              path="/homeclass7"
              element={<Layout><HomeProtectedRoute user={user}><Class7/></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/class7"
              element={<Class7Dash />}
          />
          <Route
              path="/class7update/:class7_id"
              element={<UpdateClass7Book />}
          /> */}
          <Route
              path="/homeclass8"
              element={<Layout><HomeProtectedRoute user={user}><Class8/></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/class8"
              element={<Class8Dash />}
          />
          <Route
              path="/class8update/:class8_id"
              element={<UpdateClass8Book />}
          /> */}
          <Route
              path="/homeclass9"
              element={<Layout><HomeProtectedRoute user={user}><Class9/></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/class9"
              element={<Class9Dash />}
          />
          <Route
              path="/class9update/:class9_id"
              element={<UpdateClass9Book />}
          /> */}
          <Route
              path="/homeclass10"
              element={<Layout><HomeProtectedRoute user={user}><Class10/></HomeProtectedRoute></Layout>}
          />
           {/* <Route
              path="/class10"
              element={<Layout><Class10/></Layout>}
          />
          <Route
              path="/class10update/:class10_id"
              element={<UpdateClass10Book />}
          /> */}
          <Route
              path="/homeclass11"
              element={<Layout><HomeProtectedRoute user={user}><Class11/></HomeProtectedRoute></Layout>}
          />
          <Route
              path="/homeclass12"
              element={<Layout><HomeProtectedRoute user={user}><Class12/></HomeProtectedRoute></Layout>}
          />
          <Route
              path="/modal"
              element={<ProductModal bookPath={selectedBookPath}/>}
          />
          <Route
              path="/user"
              element={<LayoutDashboard><ProtectedRoute user={user}><User /></ProtectedRoute></LayoutDashboard>}
          />
          <Route
              path="/viewpdf/:publisher_id"
              element={<ViewPdf />}
          />
          <Route
              path="/contactinfo"
              element={<LayoutDashboard><ProtectedRoute user={user}><ContactInfo /></ProtectedRoute></LayoutDashboard>}
          />
          <Route
              path="*"
              element={<Navigate to="/" />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
