import React from 'react'
// import 
//  {BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsSearch, BsJustify}
//  from 'react-icons/bs'

function Header({OpenSidebar}) {
  return (
    <header className='header'>
        <div className='menu-icon'>
            {/* <BsJustify className='icon' onClick={OpenSidebar}/> */}
            <i className="bi bi-justify-left " onClick={OpenSidebar} href="#"></i>
        </div>
        {/* <div className='header-left'>
            {/* <BsSearch  className='icon'/> */}
            {/* <i className="bi bi-justify-left " href="#"></i>
        </div> */} 
        <div className='header-right'>
            {/* <BsFillBellFill className='icon'/>
            <BsFillEnvelopeFill className='icon'/>
            <BsPersonCircle className='icon'/> */}
        </div>
    </header>
  )
}

export default Header
