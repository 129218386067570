// Filename - components/SubMenu.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	list-style: none;
	height: 60px;
	text-decoration: none;
	font-size: 18px;
	color: white;
	background: ${({ isFocused }) => (isFocused ? '#4c6e81' : 'transparent')};
	margin: 0 5px;

	&:hover {
		background: #4c6e81;
		cursor: pointer;
	}
`;

const SidebarLabel = styled.span`
	margin-left: 16px;
`;

const DropdownLink = styled(Link)`
	background: ${({ isFocused }) => (isFocused ? 'black' : '#4c6e81')};
	height: 60px;
	padding-left: 3rem;
	display: flex;
	align-items: center;
	text-decoration: none;	
	font-size: 18px;
	color: white;
	margin: 0 5px;

	&:hover {
		background: black;
		cursor: pointer;
		color: #000000;
	}
`;

const SubMenu = ({ item }) => {
	const [subnav, setSubnav] = useState(false);
	const [focusedItem, setFocusedItem] = useState(null);

	const showSubnav = () => setSubnav(!subnav);

	const handleFocus = (index) => {
		setFocusedItem(index);
	};

	const handleBlur = () => {
		setFocusedItem(null);
	};

	return (
		<>
			<SidebarLink style={{color:"white"}}
				to={item.path}
				onClick={item.subNav && showSubnav}
				isFocused={focusedItem === -1}
				onFocus={() => handleFocus(-1)}
				onBlur={handleBlur}
			>
				<div>
					{item.icon}
					<SidebarLabel>
						{item.title}
					</SidebarLabel>
				</div>
				<div>
					{item.subNav && subnav
						? item.iconOpened
						: item.subNav
						? item.iconClosed
						: null}
				</div>
			</SidebarLink>
			{subnav &&
				item.subNav.map((item, index) => {
					return (
						<DropdownLink
                            style={{color:"white"}}                        
							to={item.path}
							key={index}
							isFocused={focusedItem === index}
						onFocus={() => handleFocus(index)}
						onBlur={handleBlur}
						>
							{item.icon}
							<SidebarLabel>
								{item.title}
							</SidebarLabel>
						</DropdownLink>
					);
				})}
		</>
	);
};

export default SubMenu;
