import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { statesAndDistricts } from './../data/Data';
import Swal from 'sweetalert2';

const BASE_URL = require('../BaseURL/BaseURL');

const Login = ({ closeModal, bookPath }) => { 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [district, setDistrict] = useState('');
  const [districts, setDistricts] = useState([]);
  const navigate = useNavigate();

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setDistrict(''); // Reset district when state changes
    const stateData = statesAndDistricts.find(s => s.state === state);
    setDistricts(stateData ? stateData.districts : []);
  };

  const handleDistrictChange = (e) => {
    setDistrict(e.target.value);
  };

  const handleCloseModal = () => {
    localStorage.removeItem('phone');
    closeModal();
  };

  useEffect(() => {
    const storedPhone = localStorage.getItem('phone');
    if (storedPhone) {
      setPhone(storedPhone);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/customerregister`, { name, email, phone, selectedState, district });
      Swal.fire({
        icon: 'success',
        title: 'Submitted Successfully!',
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(response.data);
          localStorage.setItem('userData', JSON.stringify([response.data]));
          navigate(bookPath);
          window.location.reload();
        }
      });
    } catch (err) {
      alert('error');
      handleCloseModal();
    }
  };

  return (
    <>
      <div className='d-flex justify-content-center'>
        <p className='text-danger' style={{ fontSize: '16px' }}>Sorry, You are not a valid user. Please register below.</p>
      </div>
      <Form className="ms-3" onSubmit={handleSubmit}>
        <Row className="container my-4">
          <Col lg={12} container className='mb-3'>
            <Form.Control
              placeholder="Enter your name"
              style={{ fontSize: '18px' }}
              maxLength={20}
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </Col>
          <Col lg={12} container className='mb-4'>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              style={{ fontSize: '18px' }}
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </Col>
          <Col lg={12} container className='mb-4'>
            <Form.Control
              type="text"
              placeholder="Enter your phone number"
              style={{ fontSize: '18px' }}
              value={phone}
              maxLength={10}
              readOnly
            />
          </Col>
          <Col lg={12} container className='mb-4'>
            <select id="state" className='form-select' style={{ fontSize: '18px' }} value={selectedState} onChange={handleStateChange} required>
              <option value="">Select State</option>
              {statesAndDistricts.map((stateData, index) => (
                <option key={index} value={stateData.state}>
                  {stateData.state}
                </option>
              ))}
            </select>
          </Col>
          <Col lg={12} container className='mb-4'>
            <select
              id="district"
              className='form-select'
              value={district}
              onChange={handleDistrictChange}
              style={{ fontSize: '18px' }}
              disabled={!selectedState}
              required
            >
              <option value="">Select District</option>
              {districts.map((district, index) => (
                <option key={index} value={district}>
                  {district}
                </option>
              ))}
            </select>
          </Col>
          <Col className='d-flex justify-content-center mt-2'>
            <Button type='submit' className="btn text-white w-100" style={{ backgroundColor: '#aa5377', fontSize: '20px' }}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Login;
