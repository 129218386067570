import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './../App.css';
import { Link,useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
import LogoIcon from "./../assets/logo1.png";
import * as IoIcons from "react-icons/io";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import UserModal from './Userlogin';
import Modal from 'react-bootstrap/Modal';
import { bookGroups } from "./../data/Data";
import VisitorCounter from "./VisitorCounter";

function MegaMenu(){
  const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [isOpen ,setIsOpen] = useState(false);
    const [show, setShow] = useState(false);  
    const [user, setUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBookPath, setSelectedBookPath] = useState('');

    const modalClose = () => setShow(false);  
    const modalShow = () => setShow(true);  
    console.log(userData.length);

    useEffect(() => {
      const storedData = localStorage.getItem('userData');
      console.log(storedData);
      if (storedData) {
        try {
          const parsedData = JSON.parse(storedData);
          setUserData(parsedData);
          setIsOpen(false);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          setIsOpen(true);
        }
      } else {
        setIsOpen(true);
      }
    }, []);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleScroll = () => {
    const scrollPosition = window.scrollY;      
    
  };
  const openModal = (bookPath) => {
    setSelectedBookPath(bookPath);
    const phone = localStorage.getItem('userData');
    if(phone){
      // setIsModalOpen(false);
      navigate(bookPath);
    }
    else{
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear(); 
    setUserData(false);
    setIsOpen(true);
    navigate('/');
    // window.location.reload();
  };

  const sliceFirstNameBeforeSpace = (fullName) => {
    const firstSpaceIndex = fullName.indexOf(' ');
    if (firstSpaceIndex !== -1) {
      return fullName.slice(0, firstSpaceIndex);
    }
    return fullName;
  };


  
    return(
        <>  <nav className='navbar bg-primary'>
              <div className="m-1 px-3 text-white"><VisitorCounter /></div> 
            </nav>    
            <nav className = "navbar navbar-expand pt-2  pb-0 box sticky-top brownBg" >
                <div className='container-fluid px-3'>                 
                <div className='d-flex ' style={{marginTop:'-10px'}}><img style={{borderRadius:'50%'}} src={LogoIcon} width={45} height={45} />&nbsp;<h2 className='text-white mt-2'>A.B. Books India</h2></div>
                <div class = "navbar-collapse ">
                <ul class = "container-fluid navbar-nav mb-lg-0 my-0 pb-0 text-white">
                    <li class="nav-item ">
                    <Link to='/home'style={{ fontSize:'1.1rem'}} className='text-white'>Home</Link>
                    </li>

                    <li class="nav-item">
                    <Link to='/AboutUs'style={{ fontSize:'1.1rem'}} className='text-white'>About Us</Link>
                    </li>
                    <li class="nav-item">
                    <Link style={{ fontSize:'1.1rem'}} className='text-white'>Books</Link>
                    <div class = "sub-menu " style={{width:'220px',fontWeight:'bolder',position:'absolute',left:'45%',backgroundColor:'green'}}>
                      <ul className='py-2'>
                      {bookGroups.map((book) => (<li>                                                        
                                <div id='budget_div1' className='mb-2'>
                                    <h4 className='container-fluid text-white pointer d-flex py-1' onClick={() => openModal(book.path)}><span className='pe-4'>{book.bookname}&nbsp;{book.bookname1}</span></h4>
                                </div>
                              
                            </li>))}
                            <br />
                        </ul>
                        </div>
                 
                    </li>
                    {user ? (
          <div>
            <h1>Welcome, {user.phone}</h1>
          </div>
        ) : (
          <div>{isModalOpen && <UserModal closeModal={closeModal} setUser={setUser} bookPath={selectedBookPath}/>}</div>
        )}
                    <li class="nav-item">
                    <Link to='/Blog'style={{ fontSize:'1.1rem'}} className='text-white'>Tutorial Video</Link>
                    </li>

                    <li class="nav-item">
                    <Link to='/ContactUs'style={{ fontSize:'1.1rem'}} className='text-white'>Contact Us</Link>
                    </li>                    
                </ul>               
                </div>
                <div>
        
                {userData.length > 0 && (
                <div className='d-flex justify-content-center me-4 mb-0'>
                  {userData.map(user => (
                    <div key={user.id}>
                    {/* <IoIcons.IoMdPerson color='white' className='text-center ms-3'/> */}
                    <Dropdown className='d-flex'> 
                        <Dropdown.Toggle variant="secondary" className="custom-dropdown-toggle ms-1" style={{borderRadius:'50%',fontSize:'10px'}}><span style={{fontSize:'15px'}} className='bi bi-person-circle'></span></Dropdown.Toggle> 
                        <p style={{fontSize:'18px',color:'white',fontWeight:'bolder'}} className='mt-2'>&nbsp;{sliceFirstNameBeforeSpace(user.name)}</p>
                        <Dropdown.Menu> 
                          <Dropdown.Item href="#">                         
                                  {/* <button onClick={openProfile}>Open Profile</button> */}
                                  <span variant="transparent" style={{fontSize:'20px'}} className='ms-0' onClick={modalShow}>  
                                    Profile
                                  </span>  
                              <Modal size='sm' show={show} onHide={modalClose} className="modal-dialog-right" style={{backgroundColor:'transparent'}}>  
                                <Modal.Header closeButton>  
                                  {/* <Modal.Title>Small Modal</Modal.Title>   */}                          
                                  <Modal.Body>  
                                    <span style={{fontSize:'20px'}} className='bi bi-person-circle'></span>
                                    <p>{user.name}</p>
                                    <p>Email: {user.email}</p>
                                    <p>Phone: {user.phone}</p>
                                    <p>State: {user.state}</p>
                                    <p>District: {user.district}</p>
                                  </Modal.Body>
                                </Modal.Header>                         
                            </Modal>      
                          </Dropdown.Item> 
                          {/* <Dropdown.Item href="#"> 
                            Settings 
                          </Dropdown.Item>  */}
                          <Dropdown.Item href="#" style={{fontSize:'20px'}}  onClick={handleLogout}> 
                            Logout 
                          </Dropdown.Item> 
                        </Dropdown.Menu> 
                      </Dropdown> 
                              
                    {/* <p>Email: {user.email}</p>
                    <p>Phone: {user.phone}</p>
                    <p>State: {user.state}</p> */}
                   
                    </div>
                  ))}
                </div>
                  ) }
                </div>
                
                {isOpen && <Link to='/login'><div className='d-flex' >
                    <span className='text-white login-div'>Login</span><IoIcons.IoMdLogIn color='white' className='login-div'/>
                </div>
                </Link>}
                <div id='sidebar_btn'>
                    <Sidebar style={{fontSize:'1.1rem'}} />
                </div>
                </div>
            </nav>
            
        </>
    )
}

export default MegaMenu;