import React, { useState, useEffect } from 'react';
import UserModal from './Userlogin';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styled from "styled-components";
import { bookGroups, nepGroup } from "./../data/Data";
import { ArrowRight } from 'react-bootstrap-icons';
//import HeaderImage from "./../assets/abbbbookkks.png";
import HeaderImageFont from "./../assets/abbooks.png";
import TopNav from "./Topnav";
import Footer1 from "./Footer1";
import CardSlider from "./Slider";
import LogoIcon from "./../assets/logo1.png";
import { Link, useLocation,useNavigate } from 'react-router-dom';
import axios from 'axios';

const BASE_URL = require('../BaseURL/BaseURL');

function GridExample() {

  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBookPath, setSelectedBookPath] = useState('');

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/session`);
        if (response.data.loggedIn) {
          console.log(response.data.user);
          setUser(response.data.user);
        }
      } catch (error) {
        console.error('Error checking session', error);
      }
    };

    checkSession();
  }, []);

  const location = useLocation();  

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]); 

  const openModal = (bookPath) => {
    setSelectedBookPath(bookPath);
    const phone = localStorage.getItem('userData');
    if(phone){
      // setIsModalOpen(false);
      navigate(bookPath);
    }
    else{
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* <header className='megamenu_header'>
        <TopNav />
      </header> */}
      <div>
        <div  className="img-fluid">  
            <img className='img-fluid homeimagebanner' src={HeaderImageFont} alt="Header Image Font" height={414}/> 
        </div>
        <marquee scrollamount="15" className="py-3" >"Happy Ganesh Puja" All the books will be add in the inventory very soon.</marquee>
        <div className='arithmetic-background ' id="section1" style={{marginTop:'-7px'}}>
        <div className="card-list-container container-fluid d-flex justify-content-center">
        <div className="card-list py-5">
          {bookGroups.map((book) => (              
                <Card className=' card1_div px-3 ' onClick={() => openModal(book.path)}>
                  <Card.Img variant="top" src={book.img} height={260} className='container mt-3 py-2 bg-light'/>
                  <Card.Body>
                    <Card.Title className='text-center text-light'><h2>{book.name}</h2></Card.Title>
                    <Card.Text className='text-center'>
                      <ArrowRight color="black" size={40} />
                    </Card.Text>
                  </Card.Body>
                </Card>
               
            ))} </div>
    </div>
        </div>
        <div className='nepbg pt-4 pb-5 container-fluid' id="section2">
          <h1 className='mx-5 mb-5 text-white'>NEP GROUP</h1><br />
          <div className="card-list-container container-fluid d-flex justify-content-center">
          <div className="card-list py-3">       
            {nepGroup.map((book) => (
              <Col key={book.id} className=' mb-5 gx-5'>
                <Card className='card2_div' onClick={() => openModal(book.path)} style={{ borderRadius:'35px'}}>
                  <Card.Img variant="top" src={book.img} height={300} className='p-2' style={{borderRadius:'35px'}}/>
                </Card>
                <h2 className='text-white text-center mt-2'>{book.name}</h2>
              </Col>        
            ))}
          </div>
          </div>
        </div>
        {user ? (
          <div>
            <h1>{user.phone}</h1>
          </div>
        ) : (
          <div>{isModalOpen && <UserModal closeModal={closeModal} setUser={setUser} bookPath={selectedBookPath}/>}</div>
        )}

      </div>
      <div className='slider-div container-fluid '>
        <CardSlider  />
      </div>
      <div className='px-5 py-4 divbg'>
        <Row xs={2}>
        <Col className="d-flex flex-column flex-md-row ">
            <img
              style={{ borderRadius: '50%' }}
              src={LogoIcon}
              width={50}
              height={50}
              alt="Logo Icon"
              className="d-md-inline d-block pb-2  mb-md-0"
            />
            <h2 className="text-white ms-md-2 mt-2">A.B. Books India</h2>
          </Col>
          <Col className="d-flex flex-column flex-md-row  d-flex justify-content-end ">
            {/* <img
              style={{ borderRadius: '50%' }}
              src={LogoIcon}
              width={50}
              height={50}
              alt="Logo Icon"
              className="d-md-inline d-block  mb-md-0"
            /> */}
            <h2 className='text-white ms-md-2 mt-1'>ACR Books India </h2>
          </Col>
          </Row>
      </div><br/>
      {/* <footer>
        <Footer1 />
      </footer> */}
    </>
  );
}

export default GridExample;

const Wrapper1 = styled.section`
  padding-top: 0px;
  width: 100%;
  min-height: 414px;
  
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
