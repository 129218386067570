// Layout.js
import React from 'react';
import TopNav from './Topnav';
import Footer from './Footer1';

const Layout = ({ children }) => {
  return (
    <>
      <TopNav />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
