// UserModal.js
import React, { useState } from 'react';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import { state } from '../data/Data';
import Modal from './Modal';
import Swal from 'sweetalert2';

const BASE_URL = require('../BaseURL/BaseURL');

const UserModal = ({ closeModal, setUser, bookPath }) => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [pagename, setbookgroup] = useState(bookPath);
  const [isVisible, setIsVisible] = useState(false);
  const [phoneError, setPhoneError] = useState('');

  const handleBookGroup = (e) => {
    setbookgroup(e.target.value);
};

const handleInputChange = (e) => {
  setPhone(e.target.value);
};

const validatePhoneNumber = (number) => {
  const regex = /^[6-9][0-9]{9}$/; // Adjust the regex based on your phone number format requirement
  return regex.test(number);
};

const handleCloseModal = () => {
  localStorage.removeItem('phone');
  closeModal();
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePhoneNumber(phone)) {
      setPhoneError('Invalid phone number. Please enter a 10-digit number.');
      return;
    } else {
      setPhoneError('');
    }
    try {
      const response = await axios.post(`${BASE_URL}/userlogin`, { phone }, { withCredentials: true });
      console.log(response.data);
      if (response.data.status === 1) {
        setUser(response.data.user);
        Swal.fire({
          icon: 'success',
          title: 'Login Successfully!',
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.setItem('userData', JSON.stringify(response.data.re));
            localStorage.setItem('phone', response.data.phone);
            console.log(response.data);
            navigate(bookPath);
            window.location.reload();
            closeModal();           
          }
        });
        
      } else {
        localStorage.setItem('phone', response.data.phone);
        setIsVisible(!isVisible);
        

      }
    } catch (error) {
      console.error('Login error:', error.response.data);
      navigate("/modal");
    }

  };

  return (
    <div className="modal-overlay">
      <div className="container modal-content p-4 bg-light mx-3 my-3" style={{ width: "500px" ,borderRadius: '15px'}}>
        <div className='d-flex justify-content-end'>
          <button onClick={handleCloseModal} className='btn btn-close'></button>
        </div>
        <p className="text-center mb-0">      
                <input type="text" className='text-dark bg-light' onChange={handleBookGroup} value={bookPath} style={{border:'none',display:'none'}} readOnly />
          </p>
        {!isVisible && <Form className="container modal-form pt-1" onSubmit={handleSubmit}>
          <Row className="container my-4">
            <Col lg={12} className='mb-3'>
              <Form.Label htmlFor="inputphonenumber" style={{ fontSize: '17px', color: 'green' }}>Enter your phone number to check for a valid user:</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter Phone Number"
                  aria-label="Phone Number"
                  aria-describedby="basic-addon2"
                  value={phone}
                  maxLength={10}
                  onChange={handleInputChange}
                  style={{ fontSize: '18px',color:'black' }} type='text'
                  required
                />
                <Button variant="success" id="button-addon2" type='submit'>
                  Go
                </Button>                
              </InputGroup>
              {phoneError && <div style={{ color: 'red' }}>{phoneError}</div>}
            </Col>    
          </Row>
        </Form>}
        { isVisible && <Modal bookPath={bookPath}/>}
      </div>
    </div>
  );
};

export default UserModal;
