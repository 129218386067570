// Filename - components/SidebarData.js

import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

// export const SidebarData = [
//     {
// 		title: "Home",
// 		path: "/",
// 		icon: <FaIcons.FaHome />,
// 	},
// 	{
// 		title: "About Us",
// 		path: "/Aboutus",
// 		icon: <AiIcons.AiFillHome />,
// 	},
//     {
// 		title: "Books",
// 		icon: <FaIcons.FaBookOpen />,

// 		iconClosed: <RiIcons.RiArrowDownSFill />,
// 		iconOpened: <RiIcons.RiArrowUpSFill />,

// 		subNav: [
// 			{
// 				title: "Book1",
// 				path: "/gallery",
// 				
// 			},
// 			{
// 				title: "Book2",
// 				path: "/gallery",
// 				icon: <IoIcons.IoMdBook />,
// 			},
// 		],
// 	},
//     {
// 		title: "Tutorial Video",
// 		path: "/Blog",
// 		icon: <FaIcons.FaVideo />,
// 	},
// 	{
// 		title: "Contact Us",
// 		path: "/ContactUs",
// 		icon: <FaIcons.FaPhone />,
// 	},
	
// 	{
// 		title: "Support",
// 		path: "/support",
// 		icon: <IoIcons.IoMdHelpCircle />,
// 	},
//     {
// 		title: "Login",
// 		path: "/Login",
// 		icon: <IoIcons.IoMdLogIn />,
// 	}
// ];
export const NEPdata = [
    {
        title: "Nursery",
        path: "/homenursery",
        
    },
    {
        title: "LKG",
        path: "/homelkg",
        
    },
    {
        title: "UKG",
        path: "/homeukg",
        
    },
    {
        title: "Class 1",
        path: "/homeclass1",
        
    },
    {
        title: "Class 2",
        path: "/homeclass2",
        
    },
    {
        title: "Class 3",
        path: "/homeclass3",
        
    },
    {
        title: "Class 4",
        path: "/homeclass4",
        
    },
    {
        title: "Class 5",
        path: "/homeclass5",
        
    },
    {
        title: "Class 6",
        path: "/homeclass6",
        
    },
    {
        title: "Class 7",
        path: "/homeclass7",
        
    },
    {
        title: "Class 8",
        path: "/homeclass8",
        
    },
    {
        title: "Class 9",
        path: "/homeclass9",
        
    },
    {
        title: "Class 10",
        path: "/homeclass10",
        
    },
    
];