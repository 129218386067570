import React,{useState} from "react";
import styled from "styled-components";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import TopNav from './Topnav';
import ContactImage from "../assets/contactbanner.jpg";
import Footer1 from "./Footer1";
import axios from "axios";
import Swal from "sweetalert2";
import { socialIcons } from "./../data/Data";

const BASE_URL = require('../BaseURL/BaseURL');

const isFnameValid = (fname) => {
  const fnamePattern = /(^[A-Za-z]{0,16})([ ]{0,1})([A-Za-z]{0,16})?([ ]{0,1})?([A-Za-z]{0,16})?([ ]{0,1})?([A-Za-z]{0,16})$/ ;
  return fnamePattern.test(fname);
} 

const isLnameValid = (lname) => {
  const lnamePattern = /(^[A-Za-z]{0,16})([ ]{0,1})([A-Za-z]{0,16})?([ ]{0,1})?([A-Za-z]{0,16})?([ ]{0,1})?([A-Za-z]{0,16})$/ ;
  return lnamePattern.test(lname);
}

const isPhoneNumberValid = (phnumber) => {
  const phNoPattern = /^[6-9][0-9]{9}$/;
  return phNoPattern.test(phnumber);
}
export default function Contact() {
  const [fname, setFname] = useState('');
  const [fnameerror, setFnameError] = useState('');

  const handleFname = (e) => {
    const fname = e.target.value;
    setFname(fname);
    if(!isFnameValid(fname)){
      setFnameError('Name should only contain letters with in 20 characters.')
    }
    else{
      setFnameError('');
    }
    return false;
  };
  const [lname, setLname] = useState('');
  const [lnameerror, setLnameError] = useState('');

  const handleLname = (e) => {
    const lname = e.target.value;
    setLname(lname);
    if(!isLnameValid(lname)){
      setLnameError('Name should only contain letters with in 20 characters.')
    }
    else{
      setLnameError('');
    }
    return false;
  };

  const [phone, setPhoneNumber] = useState('');
  const [phnoerror, setPhNoError] = useState('');

  const handlePhNumber = (e) => {
      const phoneNo = e.target.value;
      setPhoneNumber(phoneNo);
      if(!isPhoneNumberValid(phoneNo)){
        setPhNoError('Phone number should only 10 digits')
      }
      else{
        setPhNoError('');
      }
    };

    const [email, setEmail] = useState('');
    const handleEmail = (e) => {
        const emailvalue = e.target.value;
        setEmail(emailvalue);
    };
    const [message, setMessage] = useState('');
    const handleMessage = (e) => {
        const messagevalue = e.target.value;
        setMessage(messagevalue);
    };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(fnameerror){
      setFnameError('Name should only contain letters.')
    }
    else if(lnameerror){
      setLnameError('Name should only contain letters.')
    }
    else if(phnoerror){
      setPhNoError('Phone number should only 10 digits')
    }
    else{
      try {
        const response = await axios.post(`${BASE_URL}/customercontactinfo`, { fname,lname,email,phone,message});
        Swal.fire({
          icon: 'success',
          title: '<span style="color: green;">Submitted successfully.!</span>',
        }).then((result) => {
          if (result.isConfirmed) {
            setFname('');
            setLname('');
            setPhoneNumber('');
            setEmail('');
            setMessage('');
          }
        });
        
      } catch (err) {
        // setError(err.response.data.message);
        alert('error')
      }
      // alert("Submitted Successfully!");
      
    }
      
  };
  return (
    <>
    <Wrapper id="contact">    

      <div className="lightBg pb-5">
      <Wrapper1 id="about" className="container-fluid  d-flex justify-content-center"> 
        <h1 className="text-center text-white menufont" style={{paddingTop:'10%'}}>Contact Us</h1>     
        </Wrapper1>
        <div className="container-fluid">
          {/* <HeaderInfo>
            <h1 className="font40 extraBold text-center mb-5">Let's get in touch</h1>
            <br/>
            <div className="container">
              <div className="row ">
                <div className="col-lg-6 mb-3 contact_col">
                  <div className="container contact_border pt-4 contact-div1">
                    <div className="text-center contact-icon my-4 ">
                        <i class="bi bi-geo-alt-fill text-white" style={{fontSize:'30px'}}></i>
                    </div>
                    <div className="container text-center text-white my-3">
                        <h3 >Store Address</h3>
                        <p className="container" style={{fontSize:'20px'}}>
                            494/752/1352 Near D.M Public School, Basuaghai, Bhubaneswar, Odisha PIN-752101
                        </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-3">
                  <div className="container contact_border pt-4 contact-div3">
                    <div className="text-center contact-icon my-4 ">
                        <i class="bi bi-envelope-fill text-white" style={{fontSize:'30px'}}></i>
                    </div>
                    <div className="container text-center text-white my-3">
                        <h3>Message Us</h3>
                        <p className="container" style={{fontSize:'20px'}}>abbooksindia@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </HeaderInfo> */}
          <br/> <br/>
          <h1 className="text-center">Have Any Questions Or Face Any <br/> Problem Contact With Us</h1><br/><br/>
         <div className="d-flex justify-content-center mb-5">
          <Row className="container ">
            <Col lg={8}>
            <Form className="container pt-5 pb-5 mb-5 contactinfoform" onSubmit={handleSubmit}>
            <Row className=" m-1">
              <Col xs={12} md={6} container className="mb-2">
                <Form.Control placeholder="First name" 
                maxLength={20}
                value={fname}
                onChange={handleFname} style={{fontSize:'18px'}} required/>
                {<p style={{ color: 'red' }}>{fnameerror}</p>}
              </Col>
              <Col xs={12} md={6}  className="mb-2">
                <Form.Control placeholder="Last name" 
                 maxLength={20}
                 value={lname}
                 onChange={handleLname} style={{fontSize:'18px'}}  required/>
                 {<p style={{ color: 'red' }}>{lnameerror}</p>}
              </Col>
            </Row>
            <Row className=" m-1">
              <Col xs={12} md={6}  className="mb-2">
                <Form.Control type="email" placeholder="Enter email" 
                value={email}
                onChange={handleEmail} style={{fontSize:'18px'}} required/>
              </Col>
              <Col xs={12} md={6}  className="mb-2">
                <Form.Control placeholder="Phone Number" 
                 value={phone}
                 maxLength={10}
                 onChange={handlePhNumber} style={{fontSize:'18px'}} required/>
                {<p style={{ color: 'red' }}>{phnoerror}</p>}
              </Col>
            </Row>
            <Row className=" m-1">
              <Col>
                <Form.Control as="textarea" rows={5} placeholder="Message" 
                value={message}
                onChange={handleMessage} style={{fontSize:'18px'}} />
              </Col>
            </Row>
            <Row className="my-4 mx-1">
              <Col className="d-grid gap-2">
                <button className="btn text-white btn-primary" style={{fontSize:'20px'}}>
                  Submit
                </button>
              </Col>
            </Row>
            
          </Form>
            </Col>
            <Col lg={4}>
            <div className="container  my-3 py-4 ps-4 contactinfobg">
                        <h3 className="text-white contactinfotext">Store Address</h3>
                        <p className="container text-white" style={{fontSize:'19px'}}>
                            494/752/1352 Near D.M Public School, Basuaghai, Bhubaneswar, Odisha PIN-752101
                        </p>
                        <h3 className="text-white contactinfotext">Email </h3>
                        <p className="container text-white" style={{fontSize:'19px'}}>abbooksindia@gmail.com</p>
                        <h3 className="text-white contactinfotext">Social</h3>
                        {socialIcons.slice(0, 4).map((val, index) => (
                        <a className="btn btn-outline-light btn-social radius mx-1" style={{borderRadius:'50px'}} href={val.Link}>
                          {val.icon}
                        </a>
                        ))}
                    </div>
            </Col>
            </Row></div>
            
            <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3743.533175612122!2d85.85903547390929!3d20.236710814513394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a0d8f7037fbd%3A0x89b69d3c4cec882a!2sA.B.BOOKS%20INDIA!5e0!3m2!1sen!2sin!4v1721392452698!5m2!1sen!2sin" width="100%" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
         
         
          
        </div>
        
      </div>
    </Wrapper>
  
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
  
`;
const Wrapper1 = styled.section`
  padding-top: 0px;
  width: 100%;
  min-height: 414px;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${ContactImage}) center/cover no-repeat;
  @media (max-width: 960px) {
    flex-direction: column;
  }

  transition: transform 0.3s ease-in-out;
`;

