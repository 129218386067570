import React from "react";
import 'semantic-ui-css/semantic.min.css'
import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Footer from './footer';
import { Container } from 'semantic-ui-react';
import MegaMenu from "./Topnav";
import TopBar from "./TopBar";

const Gallery =() =>{
    return(
        <>
        <header>      
            <TopBar />
        </header>   
        <header className='megamenu_header'>
            <MegaMenu />
        </header>
        <section>
        <div >
            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" class="active" aria-current="true"  aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active ">
                        <div>
                            <img src="./../photobanner.jpg" class="d-block w-100" alt="..." />                            
                        </div>
                    </div>
                    <div class="carousel-item ">
                        <div>
                            <img src="./../photobanner1.jpg" class="d-block w-100" alt="..." />
                        </div>                        
                    </div>
                    <div class="carousel-item">
                        <div>
                            <img src="./../photobanner2.jpg" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev " type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon  bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span class="carousel-control-next-icon  bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>         
        </div>
        <Container fluid style={{ marginTop: '5.0em' }}>
            <div class="row row-cols-1 row-cols-md-4 g-4">
                <div class="col" style={{borderTopLeftRadius:'15px'}}>
                <div class="card h-100 imagecard" style={{borderTopLeftRadius:'20px'}}>
                <div id="carouselControls1" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../7383-outerpic.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../7383-outerpic1.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../7383_kitchen.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../7383-houseplan.png" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls1" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls1" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                    </div>
                    </div>                    
                <div class="col">
                <div class="card h-100 imagecard">
                    <div id="carouselControls2" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../9953-outerpic.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../9953-outerpic1.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../9953-houseplan.png" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls2" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls2" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </div>            
                <div class="col">
                <div class="card h-100 imagecard">
                    <div id="carouselControls3" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../2004-outerpic.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../2004-outerpic1.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../2004-kitchen.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../2004-houseplan.png " class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls3" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls3" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </div>                
                <div class="col">
                <div class="card h-100 imagecard">
                    <div id="carouselControls4" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../8510-outerpic.webp " class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../8510-houseplan.png " class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls4" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls4" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </div>
                <div class="col">
                    <div class="card h-100 imagecard">
                    <div id="carouselControls5" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../3409-outerpic.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3409-diningkitchen.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3409-bedroom.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3409-houseplan.png" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls5" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls5" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </div>
                <div class="col">
                    <div class="card h-100 imagecard">
                    <div id="carouselControls6" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../2662-outerpic.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../2662-outerpic1.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../2662-hall.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../2662-houseplan.png" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls6" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls6" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </div>
                <div class="col">
                    <div class="card h-100 imagecard">
                    <div id="carouselControls7" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../1802-outerpic.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../1802-houseplan.png" class="d-block w-100" alt="..." />
                        </div>                        
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls7" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls7" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </div>
                <div class="col">
                    <div class="card h-100 imagecard">
                    <div id="carouselControls8" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../3279-outerpic.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3279-outerpic1.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3279-kitchen.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3279-houseplan.png" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls8" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls8" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </div>
                <div class="col">
                    <div class="card h-100 imagecard">
                    <div id="carouselControls9" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../1876-outerpic.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../1876-outerpic1.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../1876-kitchen.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../1876-houseplan.png" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls9" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls9" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </div>
                <div class="col">
                    <div class="card h-100 imagecard">
                    <div id="carouselControls10" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../3760-outerpic.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3760-innerpic.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3760-hall.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3760-houseplan.png" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls10" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls10" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </div>
                <div class="col">
                    <div class="card h-100 imagecard">
                    <div id="carouselControls11" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../3936-outerpic.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3936-outerpic1.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3936-outerpic2.webp" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../3936-houseplan.png" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls11" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls11" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </div>
                <div class="col">
                <div class="card h-100 imagecard">
                    <div id="carouselControls12" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src="./../housepic1.png" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src="./../houseplan01.png" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls12" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls12" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </div>
            </div>
        </Container>
        </section>
        <footer>
            <Footer />
        </footer>
        </>  
    )
}
export default Gallery;