import React, {useState, useEffect, useRef } from "react";
import styled from "styled-components";
import TutorialImage from "./../../assets/tutorialvdo.jpg";
import TopNav from '../Topnav';
import Footer1 from "../Footer1";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import { Link } from "react-router-dom";

const BASE_URL = require('../../BaseURL/BaseURL');

export default function HomePrivatePublisher(){
    const [images, setImages] = useState([]);
    const [visibleCount, setVisibleCount] = useState(10);


    const showMoreCards = () => {
        setVisibleCount((prevCount) => prevCount + 10);
    };

    const fetchImages = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/class2images`);
            setImages(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    return(
        <>
      
        <Wrapper1 className="header-div">
          <h2 className="text-center text-white header-text">Class 2</h2>     
        </Wrapper1>
        <br />
        <Wrapper id="services" className="bg-light" >
        <div className="d-flex justify-content-end me-5 pointer">
        <Link to="/group1"><h3 className="text-info">Back</h3></Link>
                <span ><h3 className="text-info">/</h3></span>
                <Link to="/#section2"><h3 className="text-info">Home</h3></Link>
            </div> <br/><br/>
            <div className="d-flex justify-content-center">
            <Row xs={2} md={5} className="container-fluid  mb-1 ">               
              {images.slice(0, visibleCount).map((image) => (
                  <Col key={image.id} className="mb-4">
                  <Card className='card_div' style={{ borderRadius:'35px'}}>
                      <Card.Img variant="top" src={`${BASE_URL}/uploads/${image.image}`} height={200} className='p-2' style={{borderRadius:'35px'}}/>
                  </Card>
                  <div className="container-fluid bookgroupfont" style={{overflowWrap: "break-word"}}>
                  <h2 className='text-center mt-3 mb-0'>{image.name}</h2>
                  <p className='text-center my-0' style={{fontSize:'17px'}}>{image.subject}</p>
                            <p className='text-center my-0'>ISBN :&nbsp;{image.isbn}</p>
                            <p className='text-center my-0' style={{fontSize:'15px'}}>₹{image.mrp}&nbsp;&nbsp;&nbsp;<span className='textsuccess'>{image.disc}%off</span></p>
                            </div>   
                  </Col>        
              ))}
         </Row></div>
         {visibleCount < images.length && (
                    <div className='d-flex justify-content-center mb-3'>
                        <button onClick={showMoreCards} className='btn btn-info px-5'>
                            Show More
                        </button>
                    </div>
                )} 
        </Wrapper><br/><br/>
       
        </>
    )
}

const Wrapper = styled.section`
  width: 100%;
`;
const Wrapper1 = styled.section`
  padding-top: 0px;
  width: 100%;
  min-height: 150px;
  background-color: darkgreen;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 8px 22px 0 rgba(0, 0, 0, 0.20);
  
`;
