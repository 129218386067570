// Filename - components/Sidebar.js

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";


const NavIcon = styled(Link)`
	
	font-size: 2rem;
	height: 80px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
    
`;

const SidebarNav = styled.nav`
	background: linear-gradient(#0b5796, #001678, #1136eb75);;   
	width: 300px;
	height: 100%;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
    overflow-y:scroll;
    scrollbar-width: none;
	right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
	transition: 350ms;   
`;

const SidebarWrap = styled.div`
	width: 100%;
    height:100%
`;

const Sidebar = () => {
	const [sidebar, setSidebar] = useState(false);

	const showSidebar = () => setSidebar(!sidebar);

	
	return (
		<>
			<IconContext.Provider value={{ color: "#ffffff" }}>
				
					<NavIcon to="#">
						<FaIcons.FaBars
							onClick={showSidebar}
						/>
					</NavIcon>				
				<SidebarNav sidebar={sidebar}>
					<SidebarWrap >
						<NavIcon to="#">
							<AiIcons.AiOutlineClose
								onClick={showSidebar}
							/>
						</NavIcon>
						{SidebarData.map((item, index) => {
							return (
								<SubMenu
									item={item}
									key={index}
									closeSidebar={() => setSidebar(false)}
								/>
							);
						})}
					</SidebarWrap>
				</SidebarNav>
			</IconContext.Provider>
		</>
	);
};

export default Sidebar;
