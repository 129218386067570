import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams,Link,useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const BASE_URL = require('../BaseURL/BaseURL');

const UpdateImageUpload = () => {
  const navigate = useNavigate();
  const {publisher_id} = useParams();
  const [publisherbookname, setpublisherBookName] = useState('');
  const [file, setFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [fileError, setFileError] = useState('');
  

  const handleBookName = (e) => {
    setpublisherBookName(e.target.value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.size > 50 * 1024) { // 50KB limit
            setFileError('File size exceeds 50KB');
            e.target.value = null; // Clear the file input
        } else {
            setFile(selectedFile);
            setFileError('');
        }
};
const handlePdfChange = (e) => {
    setPdfFile(e.target.files[0]);
  };


  const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('image', file);
      formData.append('pdf', pdfFile);
      formData.append('publisherbookname', publisherbookname);
      
      // try {
      //     await axios.put(`${BASE_URL}/publisherupdate/${publisher_id}`,formData, {
      //         headers: {
      //             'Content-Type': 'multipart/form-data'
      //         }
      //     });
      //     alert('File uploaded successfully.');
      //     navigate("/publisher")
      // } catch (err) {
      //     console.error(err);
      //     alert('Error uploading file.');
      // }

      try {
        await axios.put(`${BASE_URL}/publisherupdate/${publisher_id}`,formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        Swal.fire({
          icon: 'success',
          title: 'Updated successfully.!',
        }).then((result) => {
          if (result.isConfirmed) {
              navigate('/publisher')
          }
        });
      } catch (err) {
        console.error(err);
        alert('Error uploading file.');
      }
    
  };


  useEffect(() => {
    axios.get(`${BASE_URL}/publisherimage/${publisher_id}`)
      .then(res => {
        const data = res.data[0]; // Assuming the response returns an array
        setpublisherBookName(data.publisherbookname);
        
      })
      .catch(err => console.log(err));
  }, [publisher_id]);




    return (
        <div className='container'>
          <div className="modal-overlay">
          <div className="modal-content p-4 bg-light w-50 " style={{ borderRadius: '15px'}}>
            <div className='d-flex justify-content-end'>
              <Link to="/publisher" className='btn btn-close mt-2 mb-4'></Link>
            </div>
            <form  className='row' onSubmit={handleSubmit}>
                <div className='col-lg-12 mb-2'>
                <label className='labelfont mb-2'>Publisher Name</label>
                <input className='form-control mb-3' value={publisherbookname} style={{fontSize:'17px'}}  type='text' onChange={handleBookName} placeholder='Publisher Name' required/>
                </div>
                
                <div className='col-lg-12 mb-2'>
                <label className='labelfont mb-2' >Upload Image<span style={{fontWeight:'bold'}}>(.jpg, .PNG, .jpeg, .webp)</span></label>
                <input className='form-control mb-2' style={{fontSize:'17px'}} accept="image/*"  type="file" onChange={handleFileChange} />
                {fileError && <h3 className="error-message text-danger mt-0">{fileError}*</h3>}
                </div>
                <div className='col-lg-12 mb-2'>
                <label className='labelfont mb-2' >Upload PDF<span style={{fontWeight:'bold'}}>(.pdf)</span></label>
                <input className='form-control mb-2' style={{fontSize:'17px'}} type="file" id="pdf" accept=".pdf" onChange={handlePdfChange} />
                </div>
                <div className='col-lg-12 mb-2'>
                <button className='btn btn-info form-control mb-3 mt-3' style={{fontSize:'18px'}}   type="submit">Submit</button>
                </div>               
            </form>
            </div>
            </div>
        </div>
    );
};

export default UpdateImageUpload;
