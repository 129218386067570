import React,{useState} from "react";
import { footerContact,footer1Contact, footerItem1,footerItem2, socialIcons } from "./../data/Data";
import LogoIcon from "./../assets/sidebarlogo.png";
import { Link,useNavigate } from "react-router-dom";
import VisitorCounter from "./VisitorCounter";
import UserModal from './Userlogin';
import ScrumlinLogo from './../assets/scrumlin_infra.png';

export default function Footer() {
  const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [isOpen ,setIsOpen] = useState(false);
    const [show, setShow] = useState(false);  
    const [user, setUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBookPath, setSelectedBookPath] = useState('');

  const openModal = (bookPath) => {
    setSelectedBookPath(bookPath);
    const phone = localStorage.getItem('userData');
    if(phone){
      // setIsModalOpen(false);
      navigate(bookPath);
    }
    else{
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <>      
      <div
        className="container-fluid footer1bg text-light footer wow fadeIn "
        data-wow-delay="0.1s"        
      >
        <div className="container-fluid py-5 d-flex justify-content-center" style={{marginTop:'-30px'}}>
          <div className="container-fluid row g-5">
            {/* <div className="col-md-6 col-lg-4">
              <div className="rounded px-5">                
                  <h2 className="text-uppercase mb-3 text-white"><img style={{borderRadius:'50%'}} src={LogoIcon} width={50} height={50} className="me-2 textcolor"/>AB Books</h2>              
                <p className="text-white mb-0">
                  Build a professional website for your hotel business and grab
                  the attention of new visitors upon your site’s launch.
                </p>
              </div>
            </div> */}
            <div className="col-md-6 col-lg-3 ps-3">
              <div className="container">
                <h6 className="section-title text-start  text-uppercase mb-4">
                  Contact
                </h6>
                {footerContact.map((val, index) => (
                  <p className="mb-2 d-flex" key={index}>
                    <div className="me-3 ">{val.icon}</div><div className="">{val.name}</div> 
                  </p>
                ))}
                <div className="d-flex pt-2">
                  {socialIcons.slice(0, 4).map((val, index) => (
                    <a className="btn btn-outline-light btn-social radius mx-1" style={{borderRadius:'50px'}} href={val.Link}>
                      {val.icon}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              {/* <div className="row gy-5 g-4 container"> */}
                {footerItem1.map((section, sectionIndex) => (
                  <div className="" key={sectionIndex}>
                    <h6 className="section-title   text-uppercase mb-4">
                      {section.header}
                    </h6>
                    {section.UnitItem.map((item, itemIndex) => (
                      <Link to={item.path} className="btn btn-link my-1" style={{textDecoration:'none'}} >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                ))}
              {/* </div> */}
              {user ? (
          <div>
            <h1>{user.phone}</h1>
          </div>
        ) : (
          <div>{isModalOpen && <UserModal closeModal={closeModal} setUser={setUser} bookPath={selectedBookPath}/>}</div>
        )}
            </div>

            <div className="col-lg-3 col-md-12">
              {/* <div className="row gy-5 g-4 container"> */}
                {footerItem2.map((section, sectionIndex) => (
                  <div className="" key={sectionIndex}>
                    <h6 className="section-title   text-uppercase mb-4">
                      {section.header}
                    </h6>
                    {section.UnitItem.map((item, itemIndex) => (
                      <h3 className="btn btn-link my-1" style={{textDecoration:'none'}} onClick={() => openModal(item.path)} key={itemIndex}>
                        {item.name}
                      </h3>
                    ))}
                  </div>
                ))}
              {/* </div> */}
              {user ? (
          <div>
            <h1>{user.phone}</h1>
          </div>
        ) : (
          <div>{isModalOpen && <UserModal closeModal={closeModal} setUser={setUser} bookPath={selectedBookPath}/>}</div>
        )}
            </div>
            
            <div className="col-md-6 col-lg-3 d-flex justify-content-center">
              <div className="container-fluid">
                <h6 className="section-title text-start  text-uppercase mb-4">
                  Contact
                </h6>
                {footer1Contact.map((val, index) => (
                  <p className="mb-2 d-flex" key={index}>
                    <div className="me-3 ">{val.icon}</div><div className="">{val.name}</div> 
                  </p>
                ))}
                <div className="d-flex pt-2">
                  {socialIcons.slice(0, 4).map((val, index) => (
                    <a className="btn btn-outline-light btn-social radius mx-1" style={{borderRadius:'50px'}} href={val.Link}>
                      {val.icon}
                    </a>
                  ))}
                </div>
                {/* <div className="mt-4"><VisitorCounter /></div>                 */}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
        <hr style={{height:'1px',backgroundColor: 'white', border: 'none'}}/><br/>
        <div className="row pb-4">
          <div className="col-lg-8 pt-2 mb-3"><span >© Copyright 2024 - A.B. Books India all right reserved.</span></div>
          <div className="col-lg-4 mt-0 ">
            <div className="mt-0">
              <span >Design Developed by</span>
              <img src={ScrumlinLogo} width={30}/>
              <a className="text-white " href="https://scrumlin.com/" target="_blank" rel="noopener noreferrer"><span className="footerscrumlin" >Scrumlin Technology</span></a>
            </div>           
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
