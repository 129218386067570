import React,{useState} from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import * as FaIcons from "react-icons/fa";
import { useNavigate,Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const BASE_URL = require('../BaseURL/BaseURL');

const Login = ({ closeModal,setUser }) => { 
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/login`, { username, password });
      console.log(response.data);
      localStorage.setItem('user', JSON.stringify(response.data));
      const user = { username,password }; // Example user object
      setUser(user);
      navigate('/dashboard');
    } catch (err) {
      setError(err.response.data.message);
    }
  };
  return (
    <div className="modal-overlay login-bg">
      <div className=" login-content bg-light pb-5 pt-3 mx-3 my-2">
      <div className='d-flex justify-content-end'>
          <Link to="/" className='btn btn-close'></Link>
        </div>
        <div className='d-flex login-icon justify-content-center'>
            <FaIcons.FaUserCog size={100} />
        </div>
        <Form className="container pt-3 " onSubmit={handleSubmit} >
            <Row className="container my-4 "  >
              <Col lg={12} container className='mb-3'>
                <Form.Control placeholder="User Name" style={{fontSize:'19px'}} 
                  maxLength={20}
                  value={username}
                  onChange={e => setUsername(e.target.value)} 
                 required/>               
              </Col>
              <Col lg={12} container className='mb-4 position-relative'>
              <Form.Control  placeholder="Password" style={{ fontSize: '19px' }}
          type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      required/>
                       <div
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: 'absolute',
                  top: '45%',
                  right: '25px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </Col>
              <Col className='d-flex justify-content-center mt-2'>
                <Button type='submit' className="btn text-white w-100" style={{backgroundColor:'#aa5377',fontSize:'20px'}}>
                  Login
                </Button>
               </Col>              
              </Row> 
          </Form>
          {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
};

export default Login;
