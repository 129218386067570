// Filename - components/SubMenu.js

import React, { useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import styled from "styled-components";
import UserModal from './Userlogin';

const SidebarLink = styled(Link)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	list-style: none;
	height: 60px;
	text-decoration: none;
	font-size: 18px;
	color: white;
	background: ${({ isFocused }) => (isFocused ? 'orangered' : 'transparent')};
	

	&:hover {
		background: orangered;
		cursor: pointer;
	}
`;

const SidebarLabel = styled.span`
	margin-left: 16px;
`;

const DropdownLink = styled.div`
	background: ${({ isFocused }) => (isFocused ? 'orangered' : 'black')};
	height: 60px;
	padding-left: 3rem;
	display: flex;
	align-items: center;
	text-decoration: none;	
	font-size: 18px;
	color: white;

	&:hover {
		background: orangered;
		cursor: pointer;
		color: #000000;
	}
`;

const SubMenu = ({ item, closeSidebar  }) => {
	const navigate = useNavigate();
	const [subnav, setSubnav] = useState(false);
	const [focusedItem, setFocusedItem] = useState(null);
	const [user, setUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBookPath, setSelectedBookPath] = useState('');

	const openModal = (bookPath) => {
		setSelectedBookPath(bookPath);
		const phone = localStorage.getItem('phone');
		if(phone){
		  // setIsModalOpen(false);
		  navigate(bookPath);
		  closeSidebar();
		}
		else{
		  setIsModalOpen(true);
		}
	  };
	
	  const closeModal = () => {
		setIsModalOpen(false);
	  };

	const showSubnav = () => setSubnav(!subnav);

	const handleFocus = (index) => {
		setFocusedItem(index);
	};

	const handleBlur = () => {
		setFocusedItem(null);
	};

	return (
		<>
			<SidebarLink style={{color:"white"}}
				to={item.path}
				onClick={() => {
          				if (item.subNav) {
            					showSubnav();
          				} else {
            					closeSidebar();
          				}
        			}}
				isFocused={focusedItem === -1}
				onFocus={() => handleFocus(-1)}
				onBlur={handleBlur}
			>
				<div>
					{item.icon}
					<SidebarLabel>
						{item.title}
					</SidebarLabel>
				</div>
				<div>
					{item.subNav && subnav
						? item.iconOpened
						: item.subNav
						? item.iconClosed
						: null}
				</div>
			</SidebarLink>
			{subnav &&
				item.subNav.map((item, index) => {
					return (
						<>
						<DropdownLink
                            style={{color:"white"}}                        
							key={index}
							isFocused={focusedItem === index}
						onFocus={() => handleFocus(index)}
						onBlur={handleBlur}
						>
							{item.icon}
							<SidebarLabel onClick={() => openModal(item.path)}>
								{item.title}
							</SidebarLabel>
						</DropdownLink>
						{user ? (
							<div>
							  <h1>{user.phone}</h1>
							</div>
						  ) : (
							<div>{isModalOpen && <UserModal closeModal={closeModal} setUser={setUser} bookPath={selectedBookPath}/>}</div>
						  )}
						  </>
					);
				})}
		</>
	);
};

export default SubMenu;
