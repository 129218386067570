import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Button } from 'semantic-ui-react'
import * as FaIcons from "react-icons/fa";

function TopBar(){
  
    return(
        <>
        <div class=" py-3 topbar_header">
            <ul class="nav nav-pills nav-justified">
                <li class="nav-item">                
                <p><FaIcons.FaClock color='white'/>&nbsp; Mon-Sat</p>
                <p>09.00AM-07.00PM</p>
                </li>
                <li class="nav-item">
                <p>352, S-2, Metro Classic Building,</p>
                <p>Saheed Nagar, Bhubaneswar. </p>
                </li>
                <li class="nav-item topbarlink">
                <a class="nav-link" href="#"><FaIcons.FaPhone color='white'/>&nbsp; Call-Us 0674-3125757</a>
                </li>
                <li class="nav-item topbarlink">
                <a class="nav-link " href="#"><FaIcons.FaEnvelope color='white' />&nbsp; info@scrumlin.infra.com</a>
                </li>
                <div className='mx-4'>
                    <a href="https://www.facebook.com/">
                    <Button circular color='facebook' icon='facebook' />
                    </a>
                    <a href='#'>
                        <Button circular color='twitter' icon='twitter' />
                    </a>
                    <a href='#'>
                        <Button circular color='pink' icon='instagram' />
                    </a>
                    <a href="https://in.linkedin.com/company/scrumlin">
                        <Button circular color='linkedin' icon='linkedin' />
                    </a>
                    <a href="https://scrumlin.com/">
                        <Button circular color='google plus' icon='google' />
                    </a>
                </div>
            </ul>
        </div>
        </>
    )
}

export default TopBar;