import React ,{useState} from "react";
import Sidebar  from "../Common/Sidebar";
import Header from '../Common/Navbar'

export default function Dashboard(){
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle)
    }
  
    return (
      <div className='grid-container'>
        
        <div>
            <h1>Dashboard</h1>
        </div>
      </div>
    )
}